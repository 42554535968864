import { Box, Image as ChakraImage, HStack, Spinner } from '@chakra-ui/react';
import { useEffect, useState, useRef } from 'react';
import CustomIcon from '../../icons/CustomIcon';

const UseFileImage = (props) => {
  const { callChildFunction, onChildFunctionCalled } = props;
  const { src, setFile } = props;

  const { w = '100px', h = '100px' } = props;
  // const { fileMethod } = useFile();
  const [exist, setExist] = useState(false);

  const { onClick } = props;

  const imageInput = useRef(null);

  useEffect(() => {
    if (callChildFunction) {
      onChildFunctionCalled();
      /*
      if (fileMethod === 1) {
        console.log(fileMethod);
      } else if (fileMethod === 2) {
        handleImageUpload();
      }
      */
    }
  }, [callChildFunction]);

  useEffect(() => {
    removeImageFile();
  }, []);

  const removeImageFile = () => {
    setExist(false);
    if (setFile) {
      setFile('', null);
    }
    imageInput.current.value = '';
  };

  const saveImageFile = () => {
    const file = imageInput.current.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (data) => {
      if (typeof data.target?.result === 'string') {
        const srcData = data.target.result;
        setFile(srcData, file);
        setExist(true);
        setTimeout(() => {
          imageInput.current.value = '';
        }, 200);
      } else {
        console.log('## image upload Failed');
      }
    };
  };

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    } else {
      handleImageUpload();
    }
  };

  const handleImageUpload = () => {
    imageInput.current.click();
  };

  return (
    <>
      <Box
        w="100%"
        // minH="200px"
        // h="200px"
        bg="#FDF6E3"
        borderRadius="14px"
        onClick={handleOnClick}
      >
        {!exist ? (
          <HStack
            minH="200px"
            h="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Box>
              <CustomIcon name="plus" w="30px" h="30px" color="#806930" />
            </Box>
          </HStack>
        ) : (
          <ChakraImage
            borderRadius="8px"
            src={src}
            w="100%"
            h="100%"
            fallback={<Spinner />}
          />
        )}
      </Box>
      <input
        onChange={saveImageFile}
        style={{ display: 'none' }}
        type="file"
        accept="image/*"
        ref={imageInput}
      />
      {/* <input type="file" accept="image/*;capture=camera" /> */}
      {/* <input type="file" accept="image/*" capture="camera" /> */}
    </>
  );
};

export default UseFileImage;
