import { atom } from 'recoil';

export const albumHistoryState = atom({
  key: 'albumHistoryState',
  default: {
    petId: 0,
    trackType: 1,
    trackOneStyle: 1,
  },
});

export const albumHistoryPageState = atom({
  key: 'albumHistoryPageState',
  default: {
    pageNum: 1,
    totalPage: 1,
    contentNum: 100,
  },
});

export const albumHistoryFileState = atom({
  key: 'albumHistoryFileState',
  default: {
    src: '',
    file: null,
    fileName: '',
  },
});

export const listAlbumHistoryState = atom({
  key: 'listAlbumHistoryState',
  default: [],
});
