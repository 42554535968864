import { Icon } from '@chakra-ui/react';
import icons from './index';

const CustomIcon = (props) => {
  const { w = '20px', h = '20px', color = '#fff' } = props;
  const { name = 'question' } = props;
  return <Icon as={icons[name]} w={w} h={h} color={color} />;
};

export default CustomIcon;
