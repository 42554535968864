import { API_URL } from '../constant/enviroment';

export const handleParseSex = (sex) => {
  if (sex === 1) {
    return '남';
  }
  return '여';
};

export const handleParseSns = (snsType) => {
  if (snsType === 1) {
    return '카카오';
  }
  if (snsType === 2) {
    return '구글';
  }
  if (snsType === 3) {
    return '애플';
  }
};

export const handleAlbumImage = (albumHistoryId, type = 3) => {
  const url = `${API_URL}/v1/file?albumHistoryId=${albumHistoryId}&type=${type}`;
  return url;
};
