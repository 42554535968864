import api from './api';

const BASE = '/normalUser';

const getNormalUser = async (data) => {
  const result = await api.get(`${BASE}`, {
    token: true,
    body: data,
  });
  return result.data;
};

const getNormalUserLogin = async (data) => {
  const result = await api.get(`${BASE}/login`, {
    token: true,
    body: data,
  });
  return result.data;
};

const getNormalUserIssueAccessToken = async (data) => {
  const result = await api.get(`${BASE}/issueAccessToken`, {
    token: true,
    body: data,
    independence: true,
  });
  return result.data;
};

const postNormalUserLogout = async (data) => {
  const result = await api.post(`${BASE}/logout`, {
    token: true,
    body: data,
  });
  return result.data;
};

const postNormalUserSignUp = async (data) => {
  const result = await api.post(`${BASE}/signUp`, {
    body: data,
  });
  return result.data;
};

export default {
  getNormalUser,
  getNormalUserLogin,
  getNormalUserIssueAccessToken,
  postNormalUserLogout,
  postNormalUserSignUp,
};
