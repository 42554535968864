import React from 'react';
import {
  Box,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import useSignAdmin from '../../hook/useSignAdmin';

const Navbar = (props) => {
  const { title } = props;
  const { adminUser, logoutAdminUser } = useSignAdmin();

  const handleLogout = () => {
    logoutAdminUser();
  };

  return (
    <Box h="50px" pt="20px" px="40px" mb="40px">
      <HStack justifyContent="space-between">
        <Box>
          <HStack>
            <Text fontSize="32px" color="#000920">
              {title}
            </Text>
          </HStack>
        </Box>
        <Box>
          <HStack>
            <Box>
              <Menu>
                <HStack>
                  <Box>
                    <MenuButton
                      color="gray.700"
                      variant="transparent-with-icon"
                      h="30px"
                    >
                      <Text
                        display={{ sm: 'none', md: 'inline-flex' }}
                        fontWeight="bold"
                      >
                        {adminUser.id} 님
                        <ChevronDownIcon
                          w="24px"
                          h="24px"
                          ml="6px"
                          color="#9A9A9A"
                        />
                      </Text>
                    </MenuButton>
                    <MenuList p="16px 8px">
                      <Flex flexDirection="column">
                        <MenuItem
                          borderRadius="8px"
                          color="gray.900"
                          onClick={handleLogout}
                        >
                          로그아웃
                        </MenuItem>
                      </Flex>
                    </MenuList>
                  </Box>
                </HStack>
              </Menu>
            </Box>
          </HStack>
        </Box>
      </HStack>
    </Box>
  );
};

export default Navbar;
