import { useEffect } from 'react';
import { KAKAO_JAVASCRIPT_KEY } from '../constant/common';
import utils from '../utils';

const useSNSShare = () => {
  const shareToTwitter = (title, url, id) => {
    const shareUrl = encodeURIComponent(
      `https://mungtok.com/image?albumHistoryId=${id}&type=3`,
    );
    const shareText = encodeURIComponent(`${title}\n`);
    setTimeout(() => {
      openWidnow(`http://twitter.com/share?url=${shareUrl}&text=${shareText}`);
    });
  };

  const shareToFacebook = (title, url, id) => {
    // `https://mungtok.com/image?albumHistoryId=${id}&type=3`,
    const shareUrl = encodeURIComponent(url);
    const shareText = encodeURIComponent(title);
    openWidnow(
      `http://www.facebook.com/sharer/sharer.php?u=${shareUrl}&t=${shareText}`,
    );
  };

  const shareToKakaoTalk = (title, url, id) => {
    if (window.Kakao === undefined) {
      return;
    }

    const kakao = window.Kakao;
    // 중복 initialization 방지
    if (!kakao.isInitialized()) {
      // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
      kakao.init(KAKAO_JAVASCRIPT_KEY);
    }
    kakao.Link.sendCustom({
      templateId: 99504,
      templateArgs: {
        title,
        content: title,
        image: url,
        id,
      },
      installTalk: true,
    });

    /*
    kakao.Share.sendDefault({
      objectType: 'feed', // 카카오 링크 공유 여러 type들 중 feed라는 타입 -> 자세한 건 카카오에서 확인
      content: {
        title, // 인자값으로 받은 title
        description: title, // 인자값으로 받은 title
        imageUrl: url,
        link: {
          mobileWebUrl: url, // 인자값으로 받은 route(uri 형태)
          webUrl: url,
        },
      },
    });
    */
  };

  const shareToNavigator = ({ text, url }) => {
    const sharedData = {
      text,
      url,
    };

    try {
      if (navigator.canShare && navigator.canShare(sharedData)) {
        navigator
          .share(sharedData)
          .then(() => {
            console.log('성공');
          })
          .catch(() => {
            console.log('취소');
          });
      }
    } catch (e) {
      console.log('실패');
    }
  };

  const openWidnow = (url) => {
    console.log(url);
    // window.open(url, '_blank');
    utils.execute('exWebBrowser', url);
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://developers.kakao.com/sdk/js/kakao.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return {
    isAvailNavigator: typeof navigator.share !== 'undefined',
    shareToTwitter,
    shareToFacebook,
    shareToKakaoTalk,
    shareToNavigator,
  };
};

export default useSNSShare;
