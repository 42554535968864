import React from 'react';
import { Button, Text } from '@chakra-ui/react';

const UnderlineButton = (props) => {
  const { onClick } = props;
  const {
    text = 'Button',
    size = 'md',
    color = 'rgba(255, 0, 0, 1)',
    fontSize = '16px',
    fontWeight = 400,
    borderColor = '#FFF',
  } = props;
  const { disable = false } = props;

  return (
    <Button
      isDisabled={disable}
      onClick={onClick}
      bg="#FFF"
      _hover={{}}
      color={color}
      size={size}
      borderColor={borderColor}
      style={{
        textDecoration: 'underline',
      }}
    >
      <Text fontWeight={fontWeight} fontSize={fontSize} color={color}>
        {text}
      </Text>
    </Button>
  );
};

export default UnderlineButton;
