import {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Box,
  HStack,
  Image,
  Radio,
  RadioGroup,
  Tab,
  TabList,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import DescriptionInput from '../../input/DescriptionInput';
import DogTab from '../../../assets/images/dog-tab.svg';
import DogTabIdle from '../../../assets/images/dog-tab-idle.svg';
import CatTab from '../../../assets/images/cat-tab.svg';
import CatTabIdle from '../../../assets/images/cat-tab-idle.svg';
import utils from '../../../utils';

const MobilePetInfoForm = forwardRef((props, ref) => {
  const childInput1Ref = useRef(null);
  const childInput2Ref = useRef(null);
  const childInput3Ref = useRef(null);
  const childInput4Ref = useRef(null);
  const childInput5Ref = useRef(null);

  const { pet, selectItems } = props;
  const { retPet } = props;

  const [tempPet, setTempPet] = useState(pet);

  useImperativeHandle(ref, () => {
    return {
      resetTempPet: () => {
        setTempPet({
          type: 1,
          sex: 1,
          name: '',
          category: '',
          birth: '',
          weight: '',
          height: '',
        });
      },
      focusChildInput1: () => {
        if (childInput1Ref.current) {
          childInput1Ref.current.focus();
        }
      },
      focusChildInput2: () => {
        if (childInput2Ref.current) {
          childInput2Ref.current.focus();
        }
      },
      focusChildInput3: () => {
        if (childInput3Ref.current) {
          childInput3Ref.current.focus();
        }
      },
      focusChildInput4: () => {
        if (childInput4Ref.current) {
          childInput4Ref.current.focus();
        }
      },
      focusChildInput5: () => {
        if (childInput5Ref.current) {
          childInput5Ref.current.focus();
        }
      },
    };
  });

  useEffect(() => {
    if (retPet) {
      retPet(tempPet);
    }
  }, [tempPet]);

  return (
    <VStack spacing={2} px="20px">
      <Box w="100%">
        <Tabs
          w="100%"
          variant="toggleButton"
          // defaultIndex={tempPet.type - 1}
          index={tempPet.type - 1}
          onChange={(index) => {
            setTempPet({
              ...tempPet,
              type: index + 1,
            });
          }}
        >
          <TabList w="100%">
            <Tab w="50%" py={0} h="24px">
              <Image src={tempPet.type === 1 ? DogTab : DogTabIdle} />
            </Tab>
            <Tab w="50%" py={0} h="24px">
              <Image src={tempPet.type === 2 ? CatTab : CatTabIdle} />
            </Tab>
          </TabList>
        </Tabs>
      </Box>
      <Box w="100%">
        <DescriptionInput
          isRequired
          ref={childInput1Ref}
          descText="반려동물 이름"
          placeholder="이름을 입력해주세요"
          value={tempPet.name}
          onChange={(e) => {
            setTempPet({
              ...tempPet,
              name: e.target.value,
            });
          }}
        />
      </Box>
      <Box w="100%">
        <DescriptionInput
          isRequired
          ref={childInput2Ref}
          descText="반려동물 생일"
          placeholder="생일을 입력해주세요"
          type="date"
          value={
            tempPet.birth.length === 8
              ? utils.parseDateToStr(tempPet.birth, '-')
              : tempPet.birth
          }
          onChange={(e) => {
            setTempPet({
              ...tempPet,
              birth: e.target.value,
            });
          }}
        />
      </Box>
      <Box w="100%" py={2}>
        <HStack justifyContent="space-between" h="30px">
          <Box>
            <HStack spacing={1}>
              <Text
                textAlign="left"
                fontWeight={500}
                fontSize="15px"
                color="#444"
              >
                반려동물 성별
              </Text>
              <Text fontWeight={500} fontSize="15px" color="#EA4335">
                *
              </Text>
            </HStack>
          </Box>
          <Box>
            <RadioGroup
              defaultValue={String(pet.sex)}
              onChange={(value) => {
                setTempPet({
                  ...tempPet,
                  sex: Number(value),
                });
              }}
            >
              <Radio value="1" pr={2}>
                <Text fontWeight={400} fontSize="15px" color="#555">
                  남자
                </Text>
              </Radio>
              <Radio value="2">
                <Text fontWeight={400} fontSize="15px" color="#555">
                  여자
                </Text>
              </Radio>
            </RadioGroup>
          </Box>
        </HStack>
      </Box>
      <Box w="100%">
        <DescriptionInput
          ref={childInput3Ref}
          selectItems={selectItems}
          value={
            selectItems.findIndex((item) => {
              return item === tempPet.category;
            }) + 1
          }
          onChange={(e) => {
            setTempPet({
              ...tempPet,
              category: selectItems[Number(e.target.value) - 1],
            });
          }}
          isRequired
          type="select"
          descText={`반려동물 ${tempPet.type === 1 ? '견종' : '묘종'}`}
          placeholder={`${tempPet.type === 1 ? '견종' : '묘종'}을 선택해주세요`}
        />
      </Box>
      <Box w="100%">
        <DescriptionInput
          ref={childInput4Ref}
          value={utils.parseToFixedNum(tempPet.height)}
          onChange={(e) => {
            setTempPet({
              ...tempPet,
              height: e.target.value,
            });
          }}
          isRequired
          type="number"
          placeholder="숫자를 입력해주세요"
          descText="반려동물 키"
          inputRight="cm"
        />
      </Box>
      <Box w="100%">
        <DescriptionInput
          ref={childInput5Ref}
          isRequired
          type="number"
          value={utils.parseToFixedNum(tempPet.weight)}
          onChange={(e) => {
            setTempPet({
              ...tempPet,
              weight: e.target.value,
            });
          }}
          placeholder="숫자를 입력해주세요"
          descText="반려동물 몸무게"
          inputRight="kg"
        />
      </Box>
    </VStack>
  );
});

export default MobilePetInfoForm;
