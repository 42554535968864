import { useState } from 'react';
import AlertModal from './AlertModal';
import ConfirmModal from './ConfirmModal';
import useModal from '../../hook/useModal';

const ModalAgent = (props) => {
  const { modalDataState, closeModal } = useModal();
  const [modalList, setModalList] = useState([]);
  const filterPopup = (props, index) => {
    const {
      data: { type },
    } = props;
    if (type === 'alert') {
      return <AlertModal key={index} {...props} />;
    }
    if (type === 'confirm') {
      return <ConfirmModal key={index} {...props} />;
    }
  };
  return (
    <>
      {/*
      {modalList.map((props, index) => {
        return filterPopup(props, index);
      })}
      */}
      {modalDataState.type === 'alert' ? <AlertModal /> : <ConfirmModal />}
    </>
  );
};

export default ModalAgent;
