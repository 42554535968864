import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { isEmpty, isNotEmpty } from '../utils/common';
import { loadingState, makeImageloadingState } from '../recoil/common';
import {
  ARGUMENT_NOT_VALID_ERROR,
  DUPLICATE_ERROR,
  ETC_ERROR,
  FILE_MAX_SIZE_ERROR,
  JPA_INTERNAL_ERROR,
  NO_DATA_ERROR,
  REQUIRE_SIGNIN_ERROR,
  REQUIRE_SIGNUP_ERROR,
  REQ_MESSAGE_NOT_READABLE_ERROR,
  REQ_METHOD_NOT_SUPPORTED_ERROR,
  SEND_EMAIL_ERROR,
  SUCCESS,
  TIME_OUT_ERROR,
  TOKEN_INVALIDE_ERROR,
  TOKEN_NOT_FOUND_ERROR,
  USER_ACTIVATE_ERROR,
  USER_NOT_FOUND_ERROR,
  USER_PERMISSION_ERROR,
} from '../constant/errorCode';
import { globalInstance, independenceInstance } from '../apis/api';
import { API_PRINT_LOG } from '../constant/enviroment';
import useSign from './useSign';
import useModal from './useModal';
import { userInfoState } from '../recoil/userInfoRecoil';
import normalUserApi from '../apis/normalUserApi';
import { MAIN } from '../constant/pageURL';
import useSignAdmin from './useSignAdmin';
import utils from '../utils';
import adminUserApi from '../apis/adminUserApi';

const useAxiosHandler = () => {
  const includeCode = [
    // SUCCESS,
    // NO_DATA_ERROR,
    REQUIRE_SIGNIN_ERROR,
    // REQUIRE_SIGNUP_ERROR,
    USER_ACTIVATE_ERROR,
    TOKEN_NOT_FOUND_ERROR,
    TOKEN_INVALIDE_ERROR,
    USER_PERMISSION_ERROR,
    USER_NOT_FOUND_ERROR,
    DUPLICATE_ERROR,
    ARGUMENT_NOT_VALID_ERROR,
    REQ_MESSAGE_NOT_READABLE_ERROR,
    REQ_METHOD_NOT_SUPPORTED_ERROR,
    JPA_INTERNAL_ERROR,
    TIME_OUT_ERROR,
    FILE_MAX_SIZE_ERROR,
    FILE_MAX_SIZE_ERROR,
    SEND_EMAIL_ERROR,
    ETC_ERROR,
  ];

  const navigate = useNavigate();
  const [customLoading, setCustomLoading] = useRecoilState(
    makeImageloadingState,
  );
  const [globalLoadingState, setGlobalLoadingState] =
    useRecoilState(loadingState);
  const [tokenIssueFlag, setTokenIssueFlag] = useState(false);

  const userInfo = useRecoilValue(userInfoState);
  const { resetAdminUserProcess } = useSignAdmin();
  const { handleLogoutNormalUser } = useSign();

  const { openModal } = useModal();

  const getheaders = (tempConfig) => {
    let headerOptions = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Access-Control-Allow-Origin': '*',
    };
    if (tempConfig?.customHeaders) {
      headerOptions = Object.assign(headerOptions, tempConfig.customHeaders);
    }
    if (tempConfig?.token) {
      headerOptions.Authorization = `Bearer ${utils.getAccessToken()}`;
    }
    return headerOptions;
  };

  const apiErrorHandle = (response) => {
    console.log(`[## API-ERROR ##] error:`, response);
    if (isNotEmpty(response.data?.errorCode)) {
      if (
        response.data?.errorCode === TOKEN_INVALIDE_ERROR ||
        response.data?.errorCode === TOKEN_NOT_FOUND_ERROR
      ) {
        return Promise.reject(
          openModal({
            text: '사용자 계정 세션이 만료 되었습니다.\n로그인 화면으로 이동합니다.',
            onAgree: () => {
              if (response.config.isAdmin) {
                resetAdminUserProcess();
                navigate(MAIN.adminLogin);
              } else {
                handleLogoutNormalUser();
                navigate(MAIN.mobileLogin);
              }
            },
          }),
        );
      }
      // DB 조회 못한 경우 (일반적으로 뒤로가기 처리)
      /*
      if (error.errorCode === JPA_INTERNAL_ERROR) {
        return openModal({
          text: '해당 정보를 찾을 수 없습니다.',
          onAgree: () => {
          },
        });
      }
      */
      // 알 수 없는 에러 (일반적으로 500에러)
      /*
      if (error.errorCode === ETC_ERROR) {
        return defaultError(error);
      }
      */
      // 그 외
      return Promise.reject(
        openModal({
          // text: error.message || '작업 요청이 실패 하였습니다.',
          text: response.data.message || '이미지 변환이 실패 하였습니다.',
          onAgree: () => {},
        }),
      );
    }
    return Promise.reject(
      openModal({
        text: '요청이 실패 하였습니다.\n관리자에게 문의하시기 바랍니다.',
      }),
    );
  };

  const errorHandler = (response) => {
    if (isNotEmpty(response.data?.errorCode)) {
      return apiErrorHandle(response);
    }
    Promise.reject(
      openModal({
        // text: error.message || '작업 요청이 실패 하였습니다.',
        text: response.data.message || '이미지 변환이 실패 하였습니다.',
        onAgree: () => {},
      }),
    );
    return response.data;
  };

  const requestHandler = (config) => {
    if (config.customLoading) {
      if (customLoading === false) {
        setCustomLoading(true);
      }
    } else if (globalLoadingState === false) {
      setGlobalLoadingState(true);
    }
    const tempConfig = config;
    tempConfig.headers = getheaders(tempConfig);
    if (API_PRINT_LOG) {
      if (tempConfig.method.toUpperCase() === 'GET') {
        console.log(
          `[ >> API-REQ ] URL '${tempConfig.url}' PARAMS`,
          tempConfig.params,
        );
      } else {
        console.log(
          `[ >> API-REQ ] URL '${tempConfig.url}' DATA`,
          tempConfig.data,
        );
      }
    }
    return tempConfig;
  };

  const responseHandler = async (response) => {
    setGlobalLoadingState(false);
    setCustomLoading(false);
    if (API_PRINT_LOG) {
      console.log(
        `[ << API-RES  ] api: ${response.config.url}, response: `,
        response,
      );
    }
    if (response.status === 500) {
      return Promise.reject(
        openModal({
          // text: error.message || '작업 요청이 실패 하였습니다.',
          text: response.data.message || '이미지 변환이 실패 하였습니다.',
          onAgree: () => {},
        }),
      );
    }
    if (response.status === 200) {
      try {
        // 재발급
        if (response.data.errorCode === TOKEN_INVALIDE_ERROR) {
          if (!tokenIssueFlag) {
            try {
              setTokenIssueFlag(true);
              const param = { refreshToken: utils.getRefreshToken() };
              let resultIssue = null;
              if (response.config.isAdmin) {
                resultIssue = await adminUserApi.getIssueAccessToken(param);
              } else {
                resultIssue = await normalUserApi.getNormalUserIssueAccessToken(
                  param,
                );
              }
              if (resultIssue.errorCode === SUCCESS) {
                utils.setAccessToken(resultIssue.data.accessToken);
                const errorRequest = response.config;
                errorRequest.headers.Authorization = `Bearer ${utils.getAccessToken()}`;
                setTokenIssueFlag(false);
                return globalInstance.request(errorRequest);
              }
              return errorHandler(response);
            } catch (catchError) {
              return errorHandler(response);
            } finally {
              setTokenIssueFlag(false);
            }
          }
        }
        // 상단 에러코드가 포함되면 에러 처리
        if (includeCode.includes(response.data.errorCode)) {
          return errorHandler(response);
        }
        return response;
      } catch (catchError) {
        return errorHandler(response);
      } finally {
        setGlobalLoadingState(false);
        setCustomLoading(false);
      }
    }
  };

  const requestInterceptor =
    globalInstance.interceptors.request.use(requestHandler);

  const responseInterceptor = globalInstance.interceptors.response.use(
    (response) => {
      return responseHandler(response);
    },
    (error) => {
      // return Promise.reject(error);
      return errorHandler(error);
    },
  );

  const independenceResponseHandler = async (response) => {
    try {
      if (
        isEmpty(response) ||
        (response.status === 200 &&
          response.data.errorCode === TOKEN_NOT_FOUND_ERROR)
      ) {
        return { errorCode: TOKEN_NOT_FOUND_ERROR };
      }
      console.log(
        `[ << INDEP-API-RES ] api: ${response.config.url}, response: `,
        response,
      );
      return response;
    } catch (catchError) {
      return {
        message: catchError,
        errorCode: TOKEN_NOT_FOUND_ERROR,
      };
    } finally {
      setGlobalLoadingState(false);
      setCustomLoading(false);
    }
  };

  const independenceRequestInterceptor =
    independenceInstance.interceptors.request.use(requestHandler);

  const independenceResponseInterceptor =
    independenceInstance.interceptors.response.use(
      (response) => {
        return independenceResponseHandler(response);
      },
      (error) => {
        return Promise.reject(error);
      },
    );

  useEffect(() => {
    return () => {
      globalInstance.interceptors.request.eject(requestInterceptor);
      globalInstance.interceptors.response.eject(responseInterceptor);
      independenceInstance.interceptors.request.eject(
        independenceRequestInterceptor,
      );
      independenceInstance.interceptors.response.eject(
        independenceResponseInterceptor,
      );
    };
  }, [
    responseInterceptor,
    requestInterceptor,
    independenceRequestInterceptor,
    independenceResponseInterceptor,
  ]);
};

export { useAxiosHandler };
