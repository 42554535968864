import { useEffect, useState, useCallback, useRef } from 'react';
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import MobileContentBox from '../../components/layouts/MobileContentBox';
import {
  MobileHeader,
  MobilePetInfoCard,
  MobilePetInfoForm,
} from '../../components';

import useSign from '../../hook/useSign';
import usePet from '../../hook/usePet';
import utils from '../../utils';
import useModal from '../../hook/useModal';
import { MAIN } from '../../constant/pageURL';

const MobileSignUpPet = () => {
  const childRef = useRef(null);
  const naviagte = useNavigate();
  const { openModal } = useModal();

  const [listPetInfo, setListPetInfo] = useState([]);
  const { postNormalUserSignUp } = useSign();
  const { pet, handleSetPet, DOG_CATEGORY, CAT_CATEGORY, postPet, resetPet } =
    usePet();
  const [selectItems, setSelectItems] = useState(DOG_CATEGORY);

  useEffect(() => {
    handleSetPet({
      ...pet,
      category: '',
    });
    if (pet.type === 1) {
      setSelectItems(DOG_CATEGORY);
    } else {
      setSelectItems(CAT_CATEGORY);
    }
  }, [pet.type]);

  const handleAddPet = async () => {
    const resutl = await handleCheckInputs();
    if (resutl) {
      listPetInfo.push({
        type: pet.type,
        sex: pet.sex,
        name: pet.name,
        category: pet.category,
        birth: pet.birth.replace(/-/g, ''),
        weight: utils.parseToFixedNum(pet.weight),
        height: utils.parseToFixedNum(pet.height),
      });
      setListPetInfo(listPetInfo);
      if (childRef.current) {
        resetPet();
        childRef.current.resetTempPet();
      }
    }
  };

  const handleRegister = async () => {
    if (listPetInfo.length === 0) {
      const resutl = await handleCheckInputs();
      if (resutl) {
        openModal({
          type: 'confirm',
          text: '회원가입 하시겠습니까?',
          onAgree: () => {
            beforePostPet();
          },
        });
      }
    } else if (
      utils.isNotEmpty(pet.name) &&
      utils.isNotEmpty(pet.category) &&
      utils.isNotEmpty(pet.weight) &&
      utils.isNotEmpty(pet.height)
    ) {
      listPetInfo.push({
        type: pet.type,
        sex: pet.sex,
        name: pet.name,
        category: pet.category,
        birth: pet.birth.replace(/-/g, ''),
        weight: utils.parseToFixedNum(pet.weight),
        height: utils.parseToFixedNum(pet.height),
      });
      setListPetInfo(listPetInfo);
    } else if (
      utils.isNotEmpty(pet.name) ||
      utils.isNotEmpty(pet.category) ||
      utils.isNotEmpty(pet.weight) ||
      utils.isNotEmpty(pet.height)
    ) {
      openModal({
        type: 'confirm',
        text: '추가 작성중인던 반려동물 정보가 있습니다.\n무시하고 회원가입 하시겠습니까?',
        onAgree: () => {
          beforePostPet();
        },
      });
    } else {
      beforePostPet();
    }
  };

  const handleCheckInputs = useCallback(async () => {
    if (utils.isEmpty(pet.name)) {
      openModal({ text: '이름을 입력해주세요' });
      if (childRef.current) {
        childRef.current.focusChildInput1();
      }
      return false;
    }
    if (utils.isEmpty(pet.birth)) {
      openModal({ text: '생일 입력해주세요' });
      if (childRef.current) {
        childRef.current.focusChildInput2();
      }
      return false;
    }
    if (utils.isEmpty(pet.category)) {
      openModal({
        text: `${pet.type === 1 ? '견종' : '묘종'}을 선택해주세요`,
      });
      if (childRef.current) {
        childRef.current.focusChildInput3();
      }
      return false;
    }
    if (utils.isEmpty(pet.height)) {
      openModal({ text: '키를 입력해주세요' });
      if (childRef.current) {
        childRef.current.focusChildInput4();
      }
      return false;
    }
    if (utils.isEmpty(pet.weight)) {
      openModal({ text: '몸무게를 입력해주세요' });
      if (childRef.current) {
        childRef.current.focusChildInput5();
      }
      return false;
    }
    return true;
  });

  const beforePostPet = async () => {
    const result = await postNormalUserSignUp();
    if (result) {
      let param = [];
      if (listPetInfo.length > 0) {
        param = [...listPetInfo];
      } else {
        param.push(pet);
      }
      const result = await postPet(param);
      if (result) {
        openModal({
          text: '회원가입 되었습니다.',
          onAgree: () => {
            naviagte(MAIN.mobileRoot);
          },
        });
      }
    }
  };

  const handleDeletePet = (paramPet) => {
    const afterListPet = listPetInfo.filter((pet) => {
      return JSON.stringify(pet) !== JSON.stringify(paramPet);
    });
    setListPetInfo(afterListPet);
  };

  return (
    <MobileContentBox>
      <Box w="100%">
        <VStack spacing={0}>
          <MobileHeader title="반려동물 정보" />
        </VStack>
      </Box>
      <Box h="calc(100% - 130px)" pb="20px" overflow="scroll">
        <VStack spacing={3}>
          <Box w="100%" px="20px">
            <VStack spacing={2}>
              {listPetInfo.length > 0 ? (
                listPetInfo.map((listPet, index) => {
                  const key = `pet_${index}`;
                  return (
                    <Box key={key} w="100%">
                      <MobilePetInfoCard
                        pet={listPet}
                        onClickDelete={() => {
                          handleDeletePet(listPet);
                        }}
                      />
                    </Box>
                  );
                })
              ) : (
                <Box w="100%">
                  <Text
                    fontWeight={400}
                    fontSize="15px"
                    color="#999"
                    textAlign="center"
                  >
                    반려동물을 간단히 소개해주세요
                  </Text>
                </Box>
              )}
            </VStack>
          </Box>
          <Box w="100%">
            <MobilePetInfoForm
              ref={childRef}
              pet={pet}
              retPet={(retPet) => {
                handleSetPet(retPet);
              }}
              selectItems={selectItems}
            />
          </Box>
        </VStack>
      </Box>
      <Box position="fixed" bottom={0} w="100%">
        <HStack spacing={0}>
          <Box w="50%">
            <Button
              onClick={handleAddPet}
              bg="#34A853"
              h="60px"
              w="100%"
              borderRadius={0}
              _hover={{}}
              _active={{}}
            >
              <Text fontWeight={700} fontSize="16px" color="#FFF">
                다른 친구가 있나요?
              </Text>
            </Button>
          </Box>
          <Box w="50%">
            <Button
              onClick={handleRegister}
              bg="#FBBC05"
              h="60px"
              w="100%"
              borderRadius={0}
              _hover={{}}
              _active={{}}
            >
              <Text fontWeight={700} fontSize="16px" color="#000">
                회원가입
              </Text>
            </Button>
          </Box>
        </HStack>
      </Box>
    </MobileContentBox>
  );
};

export default MobileSignUpPet;
