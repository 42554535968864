import utils from '../utils';
import api from './api';

const BASE = '/albumHistory';

const getListAlbumHistory = async (data) => {
  const result = await api.get(`${BASE}/listAlbumHistory`, {
    token: true,
    body: data,
  });
  return result.data;
};

const postAlbumHistory = async (data, firstFile, secondFile) => {
  console.log('rqAddAlbumHistoryDTO', data);
  const frm = new FormData();
  const blobData = new Blob([JSON.stringify(data)], {
    type: 'application/json',
  });
  frm.append('rqAddAlbumHistoryDTO', blobData);
  if (utils.isNotEmpty(firstFile?.name)) {
    frm.append('sourceFile', firstFile);
    console.log('sourceFile', firstFile);
  }
  if (utils.isNotEmpty(secondFile?.name)) {
    frm.append('trackTwoFile', secondFile);
    console.log('trackTwoFile', secondFile);
  }
  const result = await api.post(`${BASE}`, {
    token: true,
    body: frm,
    customHeaders: {
      'Content-Type': 'multipart/form-data',
      // responseType: 'arraybuffer',
      // responseType: 'blob',
    },
    customLoading: true,
  });
  return result.data;
};

export default {
  getListAlbumHistory,
  postAlbumHistory,
};
