// 공통 상수
export const KAKAO_JAVASCRIPT_KEY = '44b1ef835ff34eae1b4961eed8492782';
// 중요 모달(입력 내용 있는) 오버레이 클릭시 종료 값
// null 또는 false: 닫기 버튼으로만 닫힘, true: 오버레이 클릭으로 닫기 가능
export const MODAL_CLOSE_ON_OVERLAY_CLICK = false;

export const FORM_MIN_WIDTH_PX = '1100px';

// Storage Key
export const STROAGE_KEY = 'mungtok-persist';

// 보여지는 페이지 수
export const DEFAULT_PAGE_BLOCK = 5;

// 몇개씩 조회할 것인지
export const DEFAULT_CONTENT_NUM = 2;

// 0 전체, 1 버스, 2 기차, 3 공항
export const DEFAULT_TERMINAL_KIND = 0;

// Notification default flag 1: 발송, 2: 미발송

export const NOTIFICATION_FLAG = 2;

export const TERMINAL_KIND_VALUE_LIST = ['', '버스', '기차', '공항'];

export const DELIVERY_WEIGHT_LIST = [
  '0~5kg',
  '5~10kg',
  '10~15kg',
  '15~20kg',
  '20~25kg',
  '25~30kg',
  '30kg 이상',
];

export const DELIVERY_PACKAGE_TYPE_LIST = ['박스', '서류', '가방', '직접입력'];

export const DELIVERY_CATEGORY_LIST = [
  '농/수/축산물',
  '가공식품/음료',
  '화훼류',
  '의류/패션잡화',
  '의료/약류',
  '서류',
  '기타',
];

export const DELIVERY_PAYMENT_PAYER_LIST = ['보내는분(발신)', '받는분(수신)'];

export const DELIVERY_PAYMENT_METHOD_LIST = ['예치금', '카드', '무통장'];

export const DELIVERY_PAYMENT_EXTRA_LIST = [
  '물품 갯수 추가',
  '물품 부피 상이',
  '물품 무게 상이',
  '기상 악화',
  '시간 할증',
  '수취인 부재',
  '배송지 불일치',
  '배송지 변경',
  '기타(입력)',
];

// 1 전체, 2 미정산
export const DEFAULT_UNPAID_FEE_FLAG = 1;

// 은행 목록
export const BANK_LIST = [
  '기업은행',
  '국민은행',
  '우리은행',
  '신한은행',
  'KEB하나은행',
  '농협은행',
  '지역농축협',
  'SC은행',
  '한국씨티은행',
  '우체국',
  '경남은행',
  '광주은행',
  '대구은행',
  '도이치',
  '부산은행',
  '산림조합',
  '산업은행',
  '상호저축은행',
  '새마을금고',
  '수협',
  '신협',
  '전북은행',
  '제주은행',
  'BOA',
  'HSBC',
  'JP모간',
  '중국공상은행',
  '비엔피파리바은행',
  '중국선설은행',
  '카카오뱅크',
  '케이뱅크',
];

export const CS_USER_ID = 'csUserId';
export const TERMINAL_ID = 'terminalId';
export const TERMINAL_LINE_ID = 'terminalLineId';
export const PARTNER_USER_ID = 'partnerUserId';
export const BIZ_ID = 'bizId';
export const BIZ_USER_ID = 'bizUserId';
export const BIZ_USER_MONEY_REQUEST_ID = 'bizUserMoneyRequestId';
export const PARTNER_USER_FEE_ID = 'partnerUserFeeId';
