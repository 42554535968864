import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, HStack, IconButton, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import utils from '../../../utils';
import CustomIcon from '../../icons/CustomIcon';
import useSign from '../../../hook/useSign';
import useModal from '../../../hook/useModal';
import usePet from '../../../hook/usePet';

const MobileHeader = (props) => {
  const { logoutNormalUser } = useSign();
  const { openModal } = useModal();
  const naviagte = useNavigate();
  const { title = '', url, isReplace = false, isLogout = false } = props;
  const { onClick } = props;

  // 체크용 이후 삭제
  const { accessToken, snsInfo, normalUser } = useSign();
  const { pet, listPet } = usePet();
  const checkData = () => {
    console.log('-----------------------');
    console.log('checkData');
    console.log('-----------------------');
    console.log('accessToken', accessToken);
    // console.log('refreshToken', refreshToken);
    console.log('snsInfo', snsInfo);
    console.log('normalUser', normalUser);
    console.log('-----------------------');
    console.log('pet', pet);
    console.log('listPet', listPet);
  };

  return (
    <Box px="10px" w="100%" h="70px">
      <HStack h="100%" justifyContent="space-between" alignItems="center">
        <Box w="40px">
          <IconButton
            border="none"
            onClick={() => {
              if (onClick) {
                onClick();
              } else {
                naviagte(utils.isEmpty(url) ? -1 : url, { replace: isReplace });
              }
            }}
          >
            <ChevronLeftIcon w="25px" h="25px" />
          </IconButton>
        </Box>
        <Box onClick={checkData}>
          <Text fontWeight={700} fontSize="18px" color="#181600">
            {title}
          </Text>
        </Box>
        <Box w="40px">
          {isLogout && (
            <IconButton
              border="none"
              onClick={() => {
                openModal({
                  type: 'confirm',
                  text: '로그아웃 하시겠습니까?',
                  onAgree: () => {
                    setTimeout(() => {
                      logoutNormalUser();
                    });
                  },
                });
              }}
            >
              <CustomIcon name="logout" color="#000000" />
            </IconButton>
          )}
        </Box>
      </HStack>
    </Box>
  );
};

export default MobileHeader;
