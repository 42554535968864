import {
  Box,
  HStack,
  IconButton,
  Image,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import utils from '../../../utils';
import CustomIcon from '../../icons/CustomIcon';
import DogTab from '../../../assets/images/dog-tab.svg';
import CatTab from '../../../assets/images/cat-tab.svg';
import DeleteBin from '../../../assets/images/delete-bin.svg';
import Edit from '../../../assets/images/edit-fill.svg';

const MobilePetInfoCard = (props) => {
  const { key, pet, onClick, onClickEdit, onClickDelete } = props;
  return (
    <Box
      w="100%"
      h="140px"
      borderRadius="14px"
      bg="#FDF6E4"
      key={key}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <HStack
        key={key}
        h="100%"
        px="20px"
        justifyContent="space-between"
        alignItems="center"
        py={2}
      >
        <Box w="58px" h="58px">
          <HStack
            border="1px solid"
            borderRadius={50}
            borderColor="#FBBC05"
            bg="#FFF"
            h="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              src={pet.type === 1 ? DogTab : CatTab}
              fallback={<Spinner />}
            />
          </HStack>
        </Box>
        <Box
          w="70%"
          h="100%"
          display="flex"
          alignItems="center"
          position="relative"
        >
          <HStack position="absolute" top={0} right={0} spacing={2}>
            {onClickDelete && (
              <Box>
                <IconButton
                  w="32px"
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (onClickDelete) {
                      onClickDelete(pet);
                    }
                  }}
                  _active={{
                    bg: '#FDF6E4',
                  }}
                  _hover={{
                    bg: '#FDF6E4',
                  }}
                >
                  <Image src={DeleteBin} />
                  {/* <CustomIcon
                    w="100%"
                    h="100%"
                    name="close"
                    color="rgba(0, 0, 0, 0.5)"
                  /> */}
                </IconButton>
              </Box>
            )}
            {onClickEdit && (
              <Box>
                <IconButton
                  w="32px"
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (onClickEdit) {
                      onClickEdit(pet);
                    }
                  }}
                  _active={{
                    bg: '#FDF6E4',
                  }}
                  _hover={{
                    bg: '#FDF6E4',
                  }}
                >
                  <Image src={Edit} />
                </IconButton>
              </Box>
            )}
          </HStack>
          <VStack spacing={0}>
            <Box w="100%" pb={2}>
              <Text fontWeight={700} fontSize="16px" color="#222">
                {pet.name}
              </Text>
            </Box>
            <Box w="100%">
              <Text
                fontWeight={400}
                fontSize="15px"
                color="rgba(153, 153, 153, 1)"
              >
                {`${pet.category} | ${pet.sex === 1 ? '남자아이' : '여자아이'}`}
              </Text>
            </Box>
            <Box w="100%">
              <Text
                fontWeight={400}
                fontSize="15px"
                color="rgba(153, 153, 153, 1)"
              >
                {`${utils.parseDateToStr(
                  pet.birth,
                  '.',
                )} | ${utils.parseToFixedNum(
                  pet.height,
                )}cm | ${utils.parseToFixedNum(pet.weight)}kg`}
              </Text>
            </Box>
          </VStack>
        </Box>
      </HStack>
    </Box>
  );
};

export default MobilePetInfoCard;
