const BASE = {
  mobile: '/mobile',
  conversion: '/conversion',
  admin: '/admin',
  album: '/album',
  notice: '/notice',
  myPage: '/my-page',
  signUp: '/sign-up',
};

export const MAIN = {
  root: '*',
  adminLogin: `${BASE.admin}/login`,
  mobileRoot: `${BASE.mobile}/main`,
  mobileLogin: `${BASE.mobile}/login`,
  mobileHome: `${BASE.mobile}/home`,
};

export const MEMBER = {
  root: '/member',
  adminMember: `${BASE.admin}/member`,
  adminMemberDetail: `${BASE.admin}/member/detail`,
};

export const CONVERSION = {
  root: `${BASE.conversion}`,
  mobileConversion: `${BASE.mobile}${BASE.conversion}`,
  mobileConvResult: `${BASE.mobile}${BASE.conversion}/result`,
};

export const ALBUM = {
  root: `${BASE.album}`,
  adminAlbum: `${BASE.admin}${BASE.album}`,
  adminAlbumDetail: `${BASE.admin}${BASE.album}/detail`,
  mobileAlbum: `${BASE.mobile}${BASE.album}`,
};

export const SIGN_UP = {
  root: `${BASE.signUp}`,
  mobileUser: `${BASE.mobile}${BASE.signUp}/user`,
  mobilePet: `${BASE.mobile}${BASE.signUp}/pet`,
};

export const MY_PAGE = {
  root: `${BASE.myPage}`,
  mobileMyPage: `${BASE.mobile}${BASE.myPage}`,
};
