import { useState, useCallback, useEffect } from 'react';
import { fileIndexState } from '../recoil/common';
import fileApi from '../apis/fileApi';

const useFile = () => {
  // first
  const [firstFile, setFirstFile] = useState(null);
  const [firstSrc, setFirstSrc] = useState('');
  const [firstCallChildFunc, setFirstCallChildFunc] = useState(false);
  // second
  const [secondFile, setSecondFile] = useState(null);
  const [secondSrc, setSecondSrc] = useState('');
  const [secondCallChildFunc, setSecondCallChildFunc] = useState(false);
  // file func

  // const [fileMethod, setFileMethod] = useRecoilState(fileMethodState);
  const [fileIndex, setFileIndex] = useState(fileIndexState);

  const handleChildFunctionCalled = () => {
    setFirstCallChildFunc(false);
    setSecondCallChildFunc(false);
  };

  /*
  useEffect(() => {
    handleProcessByIndex();
  }, [fileMethod]);
  */

  useEffect(() => {
    // console.log('fileIndex', fileIndex);
  }, [fileIndex]);

  const handleProcessByIndex = useCallback(() => {
    if (fileIndex === 1) {
      setFirstCallChildFunc(true);
    } else if (fileIndex === 2) {
      setSecondCallChildFunc(true);
    }
  });

  const handlePhoto = useCallback(async () => {
    console.log('handlePhoto');
    /*
    if (fileMethod !== 1) {
      setFileMethod(1);
    } else {
      handleProcessByIndex();
    }
    */
  });

  const handleAlbum = useCallback(async () => {
    console.log('handleAlbum');
    /*
    if (fileMethod !== 2) {
      setFileMethod(2);
    } else {
      handleProcessByIndex();
    }
    */
  });

  const getFile = async (tempParam) => {
    const param = {};
    if (tempParam) {
      param.albumHistoryId = tempParam.albumHistoryId;
      param.type = tempParam.type;
    }
    const result = await fileApi.getFile(param);
    console.log(result);
  };

  return {
    firstFile,
    setFirstFile,
    firstSrc,
    setFirstSrc,
    secondFile,
    setSecondFile,
    secondSrc,
    setSecondSrc,
    //
    fileIndex,
    setFileIndex,
    //
    firstCallChildFunc,
    secondCallChildFunc,
    handleChildFunctionCalled,
    getFile,
  };
};

export default useFile;
