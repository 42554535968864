import axios from 'axios';
import { API_VERSION, API_TIMEOUT, API_URL } from '../constant/enviroment';
import { getAccessToken } from '../utils/common';

export const globalInstance = axios.create({
  baseURL: API_URL,
  timeout: API_TIMEOUT,
});

export const independenceInstance = axios.create({
  baseURL: API_URL,
  timeout: API_TIMEOUT,
});

/*
const apiFunction = (method) => {
  return async (url, { body = {}, header = {}, token = false } = {}) => {
    const headersOption = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Access-Control-Allow-Origin': '*',
      ...header,
    };

    if (token) {
      headersOption.Authorization = `Bearer ${getAccessToken()}`;
    }

    if (method === 'POST') {
      const result = await httpInstance.post(API_VERSION + url, body, {
        headers: headersOption,
      });
      return result;
    }

    if (method === 'GET') {
      const result = await httpInstance.get(API_VERSION + url, {
        params: body,
        headers: headersOption,
      });
      return result;
    }

    if (method === 'DELETE') {
      const result = await httpInstance.delete(API_VERSION + url, {
        headers: headersOption,
        data: body,
      });
      return result;
    }

    if (method === 'PATCH') {
      const result = await httpInstance.patch(API_VERSION + url, body, {
        headers: headersOption,
      });
      return result;
    }

    if (method === 'PUT') {
      const result = await httpInstance.put(
        API_VERSION + url,
        { data: body },
        {
          headers: headersOption,
        },
      );
      return result;
    }
  };
};
*/

const apiFunction = (method) => {
  return async (
    url,
    {
      body = {},
      token = false,
      independence = false,
      customHeaders = {},
      customLoading = false,
      isAdmin = false,
    } = {},
  ) => {
    let instance = globalInstance;
    if (independence) {
      instance = independenceInstance;
    }
    if (method === 'POST') {
      const result = await instance.post(API_VERSION + url, body, {
        token,
        customHeaders,
        customLoading: !customLoading ? '' : customLoading,
      });
      return result;
    }

    if (method === 'GET') {
      const result = await instance.get(API_VERSION + url, {
        isAdmin,
        token,
        params: body,
      });
      return result;
    }

    if (method === 'DELETE') {
      const result = await instance.delete(API_VERSION + url, {
        token,
        data: body,
      });
      return result;
    }

    if (method === 'PATCH') {
      const result = await instance.patch(API_VERSION + url, body, {
        token,
      });
      return result;
    }

    if (method === 'PUT') {
      const result = await instance.put(
        API_VERSION + url,
        { data: body },
        { token },
      );
      return result;
    }
  };
};

const api = {
  post: apiFunction('POST'),
  get: apiFunction('GET'),
  delete: apiFunction('DELETE'),
  patch: apiFunction('PATCH'),
  put: apiFunction('PUT'),
};

export default api;
