import { atom } from 'recoil';
import { persistAtom } from './recoil-config';

export const tempAutoLoginState = atom({
  key: 'tempAutoLoginState',
  default: false,
});

export const tempSnsInfoState = atom({
  key: 'tempSnsInfoState',
  default: {
    snsToken: '',
    snsType: 0,
  },
});

export const userInfoState = atom({
  key: 'userInfoState',
  default: {
    isLogin: false,
    id: '',
    normalUserId: 0,
    name: '',
    sex: 1,
    birth: '',
  },
  effects_UNSTABLE: [persistAtom],
});

export const normalUserIdState = atom({
  key: 'normalUserIdState',
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

export const albumIdState = atom({
  key: 'albumIdState',
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
