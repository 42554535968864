import { useCallback, useEffect } from 'react';
import { Input } from '@chakra-ui/react';

const DefaultInput = (props) => {
  const { value, defaultValue = '', placeholder = '', type = 'text' } = props;
  const { isDisabled = false, readOnly = false } = props;
  const { onChange, onKeyDown } = props;
  // chakra ui prop
  const { size = 'md', textAlign = 'left', bg = '#FFF' } = props;

  const handleAutoCompleteByType = useCallback(() => {
    if (type === 'password') {
      return 'new-password';
    }
    return 'off';
  });

  const handleOnChange = (event) => {
    if (onChange) {
      return onChange(event);
    }
  };

  const handleOnKeyDown = (event) => {
    /*
    if (event.keyCode === 13) {
      if (onKeyDown) {
        return onKeyDown(event);
      }
    }
    */
    if (onKeyDown) {
      return onKeyDown(event);
    }
  };

  return (
    <Input
      name="default-input"
      autoComplete={handleAutoCompleteByType(type)}
      placeholder={placeholder}
      readOnly={readOnly}
      isDisabled={isDisabled}
      type={type}
      value={value || ''}
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
      bg={bg}
      size={size}
      textAlign={textAlign}
    />
  );
};

export default DefaultInput;
