import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import ChipButton from '../../buttons/ChipButton';
import { trackStyleState } from '../../../recoil/common';

import Cat from '../../../assets/images/cat-yellow.svg';

const MobileTrackStyleChip = () => {
  const [trackStyle, setTrackStyle] = useRecoilState(trackStyleState);
  return (
    <Box px="20px" w="100%">
      <VStack>
        <Box w="100%">
          <HStack justifyContent="space-between">
            <Box w="48%" pr={2} position="relative" h="80px">
              <VStack spacing={0}>
                <Box w="100%">
                  <Text textAlign="left" fontWeight={700} fontSize="18px">
                    어떤 스타일로
                  </Text>
                </Box>
                <Box w="100%">
                  <Text textAlign="left" fontWeight={700} fontSize="18px">
                    보여줄까요?
                  </Text>
                </Box>
              </VStack>
              <Image
                position="absolute"
                src={Cat}
                w="45px"
                h="55px"
                bottom={0}
                right={0}
              />
            </Box>
            <Box w="48%" alignSelf="end">
              <ChipButton
                isActive={trackStyle === 1}
                text="청순 스타일"
                onClick={() => {
                  setTrackStyle(1);
                }}
              />
            </Box>
          </HStack>
        </Box>
        <Box w="100%">
          <HStack justifyContent="space-between">
            <Box w="48%">
              <ChipButton
                isActive={trackStyle === 2}
                text="귀여운 스타일"
                onClick={() => {
                  setTrackStyle(2);
                }}
              />
            </Box>
            <Box w="48%">
              <ChipButton
                isActive={trackStyle === 3}
                text="우아한 스타일"
                onClick={() => {
                  setTrackStyle(3);
                }}
              />
            </Box>
          </HStack>
        </Box>
        <Box w="100%">
          <HStack justifyContent="space-between">
            <Box w="48%">
              <ChipButton
                isActive={trackStyle === 4}
                text="명랑한 스타일"
                onClick={() => {
                  setTrackStyle(4);
                }}
              />
            </Box>
            <Box w="48%">
              <ChipButton
                isActive={trackStyle === 5}
                text="시크한 스타일"
                onClick={() => {
                  setTrackStyle(5);
                }}
              />
            </Box>
          </HStack>
        </Box>
        <Box w="100%">
          <HStack justifyContent="space-between">
            <Box w="48%">
              <ChipButton
                isActive={trackStyle === 6}
                text="익살스러운 스타일"
                onClick={() => {
                  setTrackStyle(6);
                }}
              />
            </Box>
            <Box w="48%" />
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default MobileTrackStyleChip;
