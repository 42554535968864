import { useEffect, useState } from 'react';
import {
  Box,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import ContentBox from '../../../components/layouts/ContentBox';
import Navbar from '../../../components/layouts/Navbar';
import { DefaultPaginate, SearchInput } from '../../../components';
import adminUserApi from '../../../apis/adminUserApi';
import { NO_DATA_ERROR, SUCCESS } from '../../../constant/errorCode';
import utils from '../../../utils';
import { DEFAULT_CONTENT_NUM } from '../../../constant/common';
import { normalUserIdState } from '../../../recoil/userInfoRecoil';
import { MEMBER } from '../../../constant/pageURL';

const MemberMain = () => {
  const navigate = useNavigate();
  const setNormalUserId = useSetRecoilState(normalUserIdState);
  const formHeight = 627;

  const [pageCount, setPageCount] = useState(1);
  const [searchBy, setSearchBy] = useState('');
  const [totalCount, setTotalCount] = useState(1);
  const contentNum = DEFAULT_CONTENT_NUM;

  const [datas, setDatas] = useState([]);

  const headers = [
    { title: '번호', minWidth: '90px', width: 'auto' },
    { title: '회원명', minWidth: '70px', width: 'auto' },
    { title: '가입SNS', minWidth: '80px', width: 'auto' },
    { title: '반려동물 수', minWidth: '70px', width: 'auto' },
    { title: '등록일시', minWidth: '120px', width: 'auto' },
  ];

  useEffect(() => {
    getListNormalUser();
  }, [pageCount]);

  const getListNormalUser = async () => {
    const param = {
      pageNum: pageCount,
      contentNum,
    };
    if (utils.isNotEmpty(searchBy)) {
      param.searchBy = searchBy.trim();
    }
    const result = await adminUserApi.getListNormalUser(param);
    if (result.errorCode === SUCCESS) {
      setDatas(result.datas);
      setTotalCount(result.totalCount);
    } else if (result.errorCode === NO_DATA_ERROR) {
      setDatas([]);
      setTotalCount(1);
    }
  };

  const makeHeader = () => {
    return headers.map((element, index) => {
      const key = `${index}_key`;
      return (
        <Th
          key={key}
          minW={datas.length === 0 ? 'auto' : element.minWidth}
          w={element.width}
        >
          <Text>{element.title}</Text>
        </Th>
      );
    });
  };

  const handleOnClickRow = (data) => {
    setNormalUserId(data.normalUserId);
    navigate(MEMBER.adminMemberDetail);
  };

  return (
    <ContentBox>
      <Navbar title="회원관리" />

      <Box className="form-box">
        <Box px="20px">
          <HStack className="form-header" justifyContent="end">
            <Box>
              <Text fontWeight={500} fontSize="16px">
                회원명 검색
              </Text>
            </Box>
            <Box>
              <SearchInput
                value={searchBy}
                onChange={(e) => {
                  setSearchBy(e.target.value);
                }}
                onClick={() => {
                  getListNormalUser();
                }}
              />
            </Box>
          </HStack>
        </Box>
        <Box className="form-body" h={formHeight}>
          <TableContainer
            style={{ marginTop: '0px' }}
            maxW="100%"
            h="100%"
            className="default-table"
          >
            <Table>
              <Thead>
                <Tr>{makeHeader()}</Tr>
              </Thead>
              <Tbody>
                {datas.length > 0 ? (
                  datas.map((data, bodyIndex) => {
                    const key = `td_${bodyIndex}`;

                    return (
                      <Tr
                        key={key}
                        _hover={{
                          cursor: 'pointer',
                          bg: 'rgba(245, 247, 251, 1)',
                        }}
                        onClick={() => {
                          handleOnClickRow(data);
                        }}
                      >
                        <Td>
                          <Text>{datas.length - bodyIndex}</Text>
                        </Td>
                        <Td>
                          <Text>{data.name || '탈퇴회원'}</Text>
                        </Td>
                        <Td>
                          <Text>
                            {utils.handleParseSns(data.snsType) || '알 수 없음'}
                          </Text>
                        </Td>
                        <Td>
                          <Text>{utils.parseAmount(data.petCount)}</Text>
                        </Td>
                        <Td>
                          <Text>
                            {utils.parseDateToStr(
                              data.createdTime,
                              '-',
                              true,
                              ':',
                            )}
                          </Text>
                        </Td>
                      </Tr>
                    );
                  })
                ) : (
                  <Tr
                    style={{
                      height: '50px',
                    }}
                  >
                    <Td colSpan={headers.length}>
                      <Text
                        style={{
                          fontWeight: 700,
                          fontSize: '15px',
                        }}
                      >
                        등록된 회원 정보가 없습니다
                      </Text>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <HStack className="form-footer" justifyContent="space-between">
          <Box>
            <DefaultPaginate pageCount={pageCount} />
          </Box>
        </HStack>
      </Box>
    </ContentBox>
  );
};

export default MemberMain;
