import { atom } from 'recoil';
import { persistAtom } from './recoil-config';

export const petState = atom({
  key: 'petState',
  default: {
    petId: 0,
    type: 1, // 1 강아지, 2 고양이
    sex: 1, // 1 남성, 2 여성
    name: '',
    category: '',
    birth: '',
    weight: '',
    height: '',
  },
});

export const prevPetState = atom({
  key: 'prevPetState',
  default: {
    type: 1, // 1 강아지, 2 고양이
    sex: 1, // 1 남성, 2 여성
    name: '',
    category: '',
    birth: '',
    weight: '',
    height: '',
  },
  effects_UNSTABLE: [persistAtom],
});

export const listPetState = atom({
  key: 'listPetState',
  default: [],
});
