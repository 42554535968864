// 환경 상수
const type = process.env.NODE_ENV; // "development" | "production"

const AUTO_LOGIN_KEY = 'app_auto_login';
const TOKEN_ID = 'app_token';
const REF_TOKEN_ID = 'app_refresh_token';

const API_PRINT_LOG = true;
const API_TIMEOUT = 60000;
const API_VERSION = '/v1';

const URL = {
  production: {
    URL: 'http://localhost:8000',
    API_URL: 'https://api.mungtok.com',
  },
  development: {
    URL: 'http://192.168.21.40:8000',
    API_URL: 'https://api.mungtok.com',
  },
};

module.exports = {
  AUTO_LOGIN_KEY,
  TOKEN_ID,
  REF_TOKEN_ID,
  API_PRINT_LOG,
  API_TIMEOUT,
  API_VERSION,
  API_URL: process.env.API_URL || URL[type].API_URL,
};
