import api from './api';

const BASE = '/file';

const getFile = async (data) => {
  const result = await api.get(`${BASE}`, {
    token: true,
    body: data,
    independence: true,
  });
  return result.data;
};

export default {
  getFile,
};
