import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text, Box, HStack, VStack, Image } from '@chakra-ui/react';
import { CustomButton, DefaultInput } from '../components';
import useSign from '../hook/useSign';
import utils from '../utils';
import useModal from '../hook/useModal';
import { MAIN, MEMBER } from '../constant/pageURL';
import ContentBox from '../components/layouts/ContentBox';
import useSignAdmin from '../hook/useSignAdmin';

const Login = () => {
  const { adminUser, getAdminUserLogin } = useSignAdmin();
  const { openModal } = useModal();
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const [pw, setPw] = useState('');

  useEffect(() => {
    if (adminUser.isLogin) {
      navigate(MEMBER.adminMember, { replace: true });
    }
  }, []);

  const checkLogin = async () => {
    if (utils.isEmpty(id)) {
      setTimeout(() => {
        openModal({ text: '아이디를 입력해 주세요.' });
      });
      return;
    }
    if (utils.isEmpty(pw)) {
      setTimeout(() => {
        openModal({ text: '비밀번호를 입력해 주세요.' });
      });
      return;
    }
    const param = {
      id,
      pw,
    };
    const result = await getAdminUserLogin(param);
    if (result) {
      navigate(MEMBER.adminMember);
    }
  };

  return (
    <ContentBox isSidebar={false}>
      <HStack h="100%" justifyContent="center" alignItems="center">
        <Box
          pt="60px"
          pb="50px"
          minW="540px"
          w="740px"
          bg="#FFFFFF"
          borderRadius="4px"
        >
          <VStack spacing={10}>
            <Box>
              <VStack spacing={0}>
                <Box>
                  <Text fontWeight={500} fontSize="20px">
                    멍톡 관리자
                  </Text>
                </Box>
              </VStack>
            </Box>
            <Box w="100%" px="134px">
              <HStack>
                <Box w="80%">
                  <VStack>
                    <Box w="100%">
                      <HStack
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box w="100px">
                          <Text
                            fontWeight={700}
                            fontSize="16px"
                            textAlign="left"
                          >
                            아이디
                          </Text>
                        </Box>
                        <Box w="100%">
                          <DefaultInput
                            placeholder="아이디를 입력하세요"
                            value={id}
                            onChange={(e) => {
                              setId(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (e.keyCode === 13) {
                                checkLogin();
                              }
                            }}
                          />
                        </Box>
                      </HStack>
                    </Box>
                    <Box w="100%">
                      <HStack
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box w="100px">
                          <Text
                            fontWeight={700}
                            fontSize="16px"
                            textAlign="left"
                          >
                            비밀번호
                          </Text>
                        </Box>
                        <Box w="100%">
                          <DefaultInput
                            placeholder="비밀번호를 입력하세요"
                            type="password"
                            value={pw || ''}
                            onChange={(e) => {
                              setPw(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (e.keyCode === 13) {
                                checkLogin();
                              }
                            }}
                          />
                        </Box>
                      </HStack>
                    </Box>
                  </VStack>
                </Box>
                <Box w="20%" h="100%">
                  <CustomButton
                    onClick={checkLogin}
                    style={{ height: '86px' }}
                    theme="dark"
                    size="lg"
                    text="로그인"
                  />
                </Box>
              </HStack>
            </Box>
          </VStack>
        </Box>
      </HStack>
    </ContentBox>
  );
};

export default Login;
