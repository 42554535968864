import { useEffect, useCallback } from 'react';
import {
  Box,
  Checkbox,
  HStack,
  IconButton,
  Radio,
  Select,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import UnderlineButton from '../../buttons/UnderlineButton';
import useModal from '../../../hook/useModal';
import usePet from '../../../hook/usePet';
import useAlbumHistory from '../../../hook/useAlbumHistory';
import MobileRegisterDrawer from '../convert/MobileRegisterDrawer';
import MobileHeader from '../common/MobileHeader';

const MobileConvertTop = () => {
  const {
    pet,
    handleSetPet,
    resetPet,
    isPrevData,
    listPet,
    getListPet,
    resetPrevPet,
  } = usePet();

  const { openModal } = useModal();

  const { getListAlbumHistory } = useAlbumHistory();

  useEffect(() => {
    if (pet.petId !== 0) {
      getListAlbumHistory();
    }
  }, [pet.petId]);

  useEffect(() => {
    getListPet();
  }, []);

  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();

  const handleRegister = () => {
    if (isPrevData()) {
      openModal({
        type: 'confirm',
        text: '임시저장 된 정보가 있습니다\n적용 하시겠습니까?',
        onAgreeText: '적용',
        onAgree: () => {
          onOpenDrawer();
        },
        onCancelText: '새로작성',
        onCancel: () => {
          resetPrevPet();
          onOpenDrawer();
        },
      });
    } else {
      onOpenDrawer();
    }
  };

  return (
    <>
      <Box w="100%">
        <VStack spacing={0}>
          <MobileHeader isReplace />
          <Box px="20px" w="100%">
            <Text fontWeight={500} fontSize="15px" color="#444444">
              반려동물 선택
            </Text>
          </Box>
          <Box px="20px" w="100%" py={2}>
            <Select
              placeholder="반려동물을 선택해주세요"
              value={pet.petId || ''}
              onChange={(e) => {
                if (e.target.value) {
                  const petId = Number(e.target.value);
                  const pet = listPet.find((pet) => {
                    return pet.petId === petId;
                  });
                  handleSetPet(pet);
                } else {
                  resetPet();
                }
              }}
            >
              {listPet.map((pet, index) => {
                const key = `pet_${index}`;
                return (
                  <option
                    key={key}
                    value={pet.petId}
                  >{`${pet.name} (${pet.category})`}</option>
                );
              })}
            </Select>
          </Box>
          <Box px="20px" w="100%">
            <HStack justifyContent="space-between" alignItems="center">
              <Box py={3}>
                <Text fontWeight={400} fontSize="13px" color="#666666">
                  * 내 반려동물이 없나요?
                </Text>
              </Box>
              <Box>
                <UnderlineButton
                  onClick={handleRegister}
                  fontWeight={400}
                  fontSize="13px"
                  color="#4285F4"
                  text="등록하기"
                />
              </Box>
            </HStack>
          </Box>
        </VStack>
      </Box>

      {isOpenDrawer && (
        <MobileRegisterDrawer
          isOpen={isOpenDrawer}
          onClose={onCloseDrawer}
          photo={() => {
            onCloseDrawer();
            // handlePhoto();
          }}
          album={() => {
            onCloseDrawer();
            // handleAlbum();
          }}
        />
      )}
    </>
  );
};

export default MobileConvertTop;
