import { Box, Text } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useAxiosHandler } from '../hook/useAxiosHandler';

const GlobalForm = () => {
  const location = useLocation();
  useAxiosHandler();

  useEffect(() => {
    // console.log(location.pathname);
  }, [location]);

  return <Box />;
};

export default GlobalForm;
