import { Search2Icon } from '@chakra-ui/icons';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { isEmpty, isNotEmpty } from '../../utils/common';

const SearchInput = (props) => {
  const { placeholder = '입력하세요', value = '' } = props;
  const {
    disabled,
    szie = 'md',
    onClick,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    isClickComp = false, // 컴포넌트 전체 클릭
  } = props;

  const handleChange = (event) => {
    if (isNotEmpty(onChange)) {
      onChange(event);
    }
  };

  const handleClick = () => {
    if (!disabled) {
      if (isClickComp) {
        handleResultClick();
      }
    }
  };

  const handleOnClick = (event) => {
    if (onClick) {
      return onClick(event);
    }
  };

  const handleResultClick = (event) => {
    handleOnClick(event);
  };

  const handleOnKeyDown = (event) => {
    if (isEmpty(onKeyDown) && event.keyCode === 13) {
      handleOnClick(event);
    } else if (onKeyDown) {
      onKeyDown(event);
    }
  };

  return (
    <InputGroup szie={szie} onClick={handleClick}>
      <Input
        _hover={{
          cursor: isClickComp ? 'pointer' : '',
        }}
        readOnly={isClickComp}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onKeyDown={handleOnKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <InputRightElement
        onClick={handleResultClick}
        _hover={{
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
      >
        <Search2Icon color="dark.default" />
      </InputRightElement>
    </InputGroup>
  );
};

export default SearchInput;
