import { useState } from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Button, IconButton } from '@chakra-ui/react';
import useModal from '../../hook/useModal';
import utils from '../../utils';
import CustomIcon from '../icons/CustomIcon';

const AlertModal = () => {
  const { modalDataState, closeModal } = useModal();

  const handleFinaly = () => {
    if (modalDataState.onAgree) {
      modalDataState.onAgree();
    }
    closeModal();
  };

  return (
    <Modal isOpen={modalDataState.isOpen} onClose={handleFinaly} size="xs">
      <ModalOverlay />
      <ModalContent alignSelf="center" borderRadius="16px">
        <ModalHeader p={0} px={2} h="30px" borderBottom="1px solid #ddd">
          <Box w="100%">
            <HStack justifyContent="end" alignItems="center">
              <Box>
                {modalDataState.isClose === true && (
                  <IconButton
                    h="23px"
                    w="23px"
                    size="xs"
                    onClick={closeModal}
                    border="none"
                    _active={{
                      bg: '#FDF6E4',
                    }}
                    _hover={{
                      bg: '#FDF6E4',
                    }}
                  >
                    <CustomIcon w="100%" h="100%" name="close" color="#000" />
                  </IconButton>
                )}
              </Box>
            </HStack>
          </Box>
        </ModalHeader>
        <ModalBody py="20px">
          {utils.isNotEmpty(modalDataState.text) && (
            <VStack spacing={1}>
              {utils
                .parseTextLine(modalDataState.text)
                .map((splitText, index) => {
                  const boxKey = `boxKey_${index}`;
                  return (
                    <Box key={boxKey}>
                      <Text textAlign="center">{splitText}</Text>
                    </Box>
                  );
                })}
            </VStack>
          )}
        </ModalBody>
        <ModalFooter p={0}>
          <HStack w="100%" justifyContent="center">
            <Box w="100%">
              <Button
                bg="#FBBC05"
                border="none"
                borderRadius="0px"
                borderBottomRadius="16px"
                w="100%"
                h="40px"
                _hover={{}}
                _active={{
                  opacity: 0.7,
                }}
                onClick={modalDataState.onAgree}
              >
                <Text color="#000" fontWeight={700} fontSize="14px">
                  {modalDataState.onAgreeText}
                </Text>
              </Button>
            </Box>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AlertModal;
