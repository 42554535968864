import { useRef } from 'react';
import {
  Box,
  Button,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import MobileContentBox from '../../components/layouts/MobileContentBox';
import {
  CustomIcon,
  DescriptionInput,
  MobileHeader,
  SectionSplitor,
  UnderlineButton,
} from '../../components';

import useSign from '../../hook/useSign';
import utils from '../../utils';
import useModal from '../../hook/useModal';
import { MAIN, SIGN_UP } from '../../constant/pageURL';
import { termInfoState } from '../../recoil/common';

const MobileSignUpUser = () => {
  const { normalUser, setNormalUser, postNormalUserSignUp } = useSign();
  const naviagte = useNavigate();
  const { openModal } = useModal();
  const [termInfo, setTermInfo] = useRecoilState(termInfoState);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const childInput1Ref = useRef(null);
  const childInput2Ref = useRef(null);

  const handleCheckInputs = async () => {
    if (utils.isEmpty(normalUser.name)) {
      openModal({ text: '성함을 입력해주세요' });
      childInput1Ref.current.focus();
      return false;
    }
    if (utils.isEmpty(normalUser.birth)) {
      openModal({ text: '생일을 입력해주세요' });
      childInput2Ref.current.focus();
      return false;
    }

    if (!termInfo.personalInfo) {
      openModal({ text: '개인정보 처리방침 동의가 필요합니다' });
      return false;
    }

    if (!termInfo.term) {
      openModal({ text: '이용약관 동의가 필요합니다' });
      return false;
    }
    return true;
  };

  const handleRegister = async () => {
    const result = await handleCheckInputs();
    if (result) {
      openModal({
        type: 'confirm',
        text: '반려동물 정보입력을 넘기고\n회원가입을 하시겠습니까?',
        onAgree: async () => {
          const result = await postNormalUserSignUp();
          if (result) {
            naviagte(MAIN.mobileRoot);
          }
        },
      });
    }
  };

  const handleSignUpPet = async () => {
    /*
    if (!normalUser.isLogin) {
      const result = await handleCheckInputs();
      if (result) {
        const result = await postNormalUserSignUp();
        if (result) {
          naviagte(SIGN_UP.mobilePet);
        }
      }
    } else {
      naviagte(SIGN_UP.mobilePet);
    }
    */
    const result = await handleCheckInputs();
    if (result) {
      naviagte(SIGN_UP.mobilePet);
    }
  };

  return (
    <MobileContentBox>
      <Box w="100%">
        <VStack spacing={0}>
          <MobileHeader title="반려인 정보" />
        </VStack>
      </Box>
      <Box w="100%">
        <VStack spacing={2} px="20px">
          <Box w="100%">
            <DescriptionInput
              isRequired
              ref={childInput1Ref}
              descText="반려인 성함"
              placeholder="성함을 입력해주세요"
              value={normalUser.name}
              onChange={(e) => {
                setNormalUser({
                  ...normalUser,
                  name: e.target.value,
                });
              }}
            />
          </Box>
          <Box w="100%" py={2}>
            <HStack justifyContent="space-between" h="30px">
              <Box>
                <HStack spacing={1}>
                  <Text
                    textAlign="left"
                    fontWeight={500}
                    fontSize="15px"
                    color="#444"
                  >
                    반려인 성별
                  </Text>
                  <Text fontWeight={500} fontSize="15px" color="#EA4335">
                    *
                  </Text>
                </HStack>
              </Box>
              <Box>
                <RadioGroup
                  defaultValue={String(normalUser.sex)}
                  onChange={(value) => {
                    setNormalUser({
                      ...normalUser,
                      sex: Number(value),
                    });
                  }}
                >
                  <Radio value="1" pr={2}>
                    <Text fontWeight={400} fontSize="15px" color="#555">
                      남자
                    </Text>
                  </Radio>
                  <Radio value="2">
                    <Text fontWeight={400} fontSize="15px" color="#555">
                      여자
                    </Text>
                  </Radio>
                </RadioGroup>
              </Box>
            </HStack>
          </Box>
          <Box w="100%">
            <DescriptionInput
              isRequired
              ref={childInput2Ref}
              descText="반려인 생일"
              type="date"
              value={
                normalUser.birth?.length === 8
                  ? utils.parseDateToStr(normalUser.birth, '-')
                  : normalUser.birth
              }
              onChange={(e) => {
                setNormalUser({
                  ...normalUser,
                  birth: e.target.value,
                });
              }}
            />
          </Box>
        </VStack>
        <SectionSplitor />
        {!normalUser.isLogin && (
          <VStack px="20px">
            <Box w="100%">
              <HStack justifyContent="space-between" alignItems="center">
                <Box>
                  <HStack>
                    <Box>
                      <IconButton
                        w="40px"
                        borderRadius="50"
                        bg="#FFF"
                        border="none"
                        onClick={() => {
                          setTermInfo({
                            ...termInfo,
                            personalInfo: !termInfo.personalInfo,
                          });
                        }}
                      >
                        <CustomIcon
                          name={
                            termInfo.personalInfo
                              ? 'fillCheckCircle'
                              : 'checkCircle'
                          }
                          color={
                            termInfo.personalInfo
                              ? '#FBBC05'
                              : 'rgba(221, 221, 221, 1)'
                          }
                          w="100%"
                          h="100%"
                        />
                      </IconButton>
                    </Box>
                    <Box>
                      <Text fontWeight={500} fontSize="15px" color="#666">
                        개인정보 처리방침 동의
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box>
                  <UnderlineButton
                    onClick={() => {
                      setTermInfo({
                        ...termInfo,
                        isTerm: false,
                      });
                      onOpen();
                    }}
                    fontWeight={500}
                    fontSize="13px"
                    color="#666"
                    text="상세보기"
                  />
                </Box>
              </HStack>
            </Box>
            <Box w="100%">
              <HStack justifyContent="space-between" alignItems="center">
                <Box>
                  <HStack>
                    <Box>
                      <IconButton
                        w="40px"
                        borderRadius="50"
                        bg="#FFF"
                        border="none"
                        onClick={() => {
                          setTermInfo({
                            ...termInfo,
                            term: !termInfo.term,
                          });
                        }}
                      >
                        <CustomIcon
                          name={
                            termInfo.term ? 'fillCheckCircle' : 'checkCircle'
                          }
                          color={
                            termInfo.term ? '#FBBC05' : 'rgba(221, 221, 221, 1)'
                          }
                          w="100%"
                          h="100%"
                        />
                      </IconButton>
                    </Box>
                    <Box>
                      <Text fontWeight={500} fontSize="15px" color="#666">
                        이용약관 동의
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box>
                  <UnderlineButton
                    onClick={() => {
                      setTermInfo({
                        ...termInfo,
                        isTerm: true,
                      });
                      onOpen();
                    }}
                    fontWeight={500}
                    fontSize="13px"
                    color="#666"
                    text="상세보기"
                  />
                </Box>
              </HStack>
            </Box>
          </VStack>
        )}
      </Box>
      <Box position="fixed" bottom={0} w="100%">
        <HStack spacing={0}>
          <Box w="50%">
            <Button
              onClick={handleSignUpPet}
              bg="#DDD"
              h="60px"
              w="100%"
              borderRadius={0}
              _hover={{}}
              _active={{}}
            >
              <Text fontWeight={700} fontSize="16px" color="#666">
                반려동물 정보입력
              </Text>
            </Button>
          </Box>
          <Box w="50%">
            <Button
              onClick={handleRegister}
              bg="#FBBC05"
              h="60px"
              w="100%"
              borderRadius={0}
              _hover={{}}
              _active={{}}
            >
              <Text fontWeight={700} fontSize="16px" color="#000">
                회원가입
              </Text>
            </Button>
          </Box>
        </HStack>
      </Box>

      <Modal onClose={onClose} size="full" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent h="100%">
          <MobileHeader
            title={termInfo.sTerm ? '약관동의' : '개인정보처리방침'}
            onClick={() => {
              onClose();
            }}
          />
          <ModalBody h="100%" p={0} px="20px" overflow="scroll">
            {termInfo.isTerm ? (
              <Box h="100%">
                <VStack spacing={3} pb="20px">
                  <Box w="100%">
                    <Text fontWeight={400} fontSize="13px" color="#666">
                      본 뭉톡 이용약관(이하 ‘본 약관’이라 함)은 뭉톡이 본
                      서비스를 이용하는 고객(이하 ‘고객’이라 함)에게 제공하는
                      모든 제품 및 서비스(이하 ‘본 서비스’라 함)의 이용에 관한
                      조건에 대해 정합니다.
                    </Text>
                  </Box>
                  <Box w="100%">
                    <Text fontWeight={500} fontSize="13px" color="#222">
                      1. 정의
                    </Text>
                  </Box>
                  <Box w="100%">
                    <Text fontWeight={400} fontSize="13px" color="#666">
                      본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                    </Text>
                  </Box>
                  <Box w="100%">
                    <Text fontWeight={400} fontSize="13px" color="#666">
                      1.1. ‘콘텐츠’란 문장, 음성, 음악, 이미지, 동영상,
                      소프트웨어, 프로그램, 코드 및 기타 정보를 말합니다.
                    </Text>
                  </Box>
                  <Box w="100%">
                    <Text fontWeight={400} fontSize="13px" color="#666">
                      1.2. ‘본 콘텐츠’란 본 서비스를 통해 접속할 수 있는
                      콘텐츠를 말합니다.
                    </Text>
                  </Box>
                  <Box w="100%">
                    <Text fontWeight={400} fontSize="13px" color="#666">
                      1.3. ‘투고 콘텐츠’란 고객이 본 서비스에 투고, 송신 또는
                      업로드한 콘텐츠를 말합니다.
                    </Text>
                  </Box>
                  <Box w="100%">
                    <Text fontWeight={400} fontSize="13px" color="#666">
                      1.4. ‘코인’이란 본 서비스가 유상으로 제공하는 서비스 또는
                      콘텐츠와 교환 가능한 선불 결제 수단 등을 말합니다.
                    </Text>
                  </Box>
                  <Box w="100%">
                    <Text fontWeight={400} fontSize="13px" color="#666">
                      1.5. ‘개별 이용약관’이란 본 서비스에 관하여 본 약관과는
                      별도로 ‘약관’, 가이드라인’, ‘정책’ 등의 명칭으로 뭉톡이
                      배포 또는 게시한 이용약관을 말합니다.
                    </Text>
                  </Box>
                  <Box w="100%">
                    <Text fontWeight={500} fontSize="13px" color="#222">
                      2. 약관 동의
                    </Text>
                  </Box>
                  <Box w="100%">
                    <Text fontWeight={400} fontSize="13px" color="#666">
                      2.1. 고객은 본 약관에 따라 본 서비스를 이용해야 합니다.
                      고객은 본 약관에 대해 동의를 했을 경우에 한하여 본
                      서비스를 이용할 수 있습니다.
                    </Text>
                  </Box>
                  <Box w="100%">
                    <Text fontWeight={400} fontSize="13px" color="#666">
                      2.2. 고객이 미성년자일 경우에는 친권자 등 법정대리인의
                      사전 동의를 얻은 후 본 서비스를 이용하십시오. 또한 고객이
                      본 서비스를 사업자를 위해 이용할 경우에는 당해 사업자 역시
                      본 약관에 동의한 후, 본 서비스를 이용하십시오.
                    </Text>
                  </Box>
                  <Box w="100%">
                    <Text fontWeight={400} fontSize="13px" color="#666">
                      2.3. 본 서비스에 적용되는 개별 이용약관이 존재할 경우,
                      고객은 본 약관 외에 개별 이용약관의 규정에 따라 본
                      서비스를 이용해야 합니다.
                    </Text>
                  </Box>
                  <Box w="100%">
                    <Text fontWeight={500} fontSize="13px" color="#222">
                      3. 약관 변경
                    </Text>
                  </Box>
                  <Box w="100%">
                    <Text fontWeight={400} fontSize="13px" color="#666">
                      본 약관의 변경이 필요하다고 판단될 경우, 뭉톡은 관련
                      법령에 따라 본 서비스 목적의 범위 내에서 본 약관을 변경할
                      수 있습니다. 이때 뭉톡은 본 약관의 변경본의 내용과 변경의
                      효력일을 본 서비스 또는 LINE의 웹사이트에 게시하거나, 이를
                      LINE이 정한 방법으로 고객에게 통지하여야 합니다. 본 약관의
                      변경본은 그 효력일로부터 효력이 발생합니다.
                    </Text>
                  </Box>
                </VStack>
              </Box>
            ) : (
              <VStack spacing={3}>
                <Box w="100%">
                  <Text fontWeight={400} fontSize="13px" color="#666">
                    뭉톡이 취급하는 모든 개인정보는 관련법령에 근거하거나
                    정보주체로부터 동의를 받고 수집‧보유 및 처리되고 있습니다.
                  </Text>
                </Box>
                <Box w="100%">
                  <Text fontWeight={400} fontSize="13px" color="#666">
                    본 개인정보 처리방침은 2023년 02월 15일부터 시행됩니다.
                    진흥원은 법령의 규정에 따라 수집․보유 및 처리하는 개인정보를
                    공공업무의 적절한 수행과 정보주체의 자유와 권리 보호를 위해
                    「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여,
                    적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다.
                    이에 「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보
                    처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을
                    신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이
                    개인정보 처리방침을 수립·공개합니다.
                  </Text>
                </Box>
                <Box w="100%">
                  <Text fontWeight={500} fontSize="13px" color="#222">
                    1. 개인정보의 처리 목적, 수집 항목, 보유 및 이용기간
                  </Text>
                </Box>
                <Box w="100%">
                  <Text fontWeight={400} fontSize="13px" color="#666">
                    뭉톡은 소관 업무수행 및 민원처리를 목적으로 최소한의
                    개인정보를 수집하고 있으며, 처리하는 개인정보의 항목 및
                    목적에 대한 자세한 사항은 해당 서비스 홈페이지 개인정보
                    처리방침에 정보주체가 확인할 수 있도록 안내하고 있습니다.
                  </Text>
                </Box>
                <Box w="100%">
                  <Text fontWeight={500} fontSize="13px" color="#222">
                    2.개인정보파일 등록 현황
                  </Text>
                </Box>
                <Box w="100%">
                  <Text fontWeight={400} fontSize="13px" color="#666">
                    뭉톡은 개인정보를 소관 업무수행 및 민원처리 등 개인정보
                    처리방침에서 명시한 범위 내에서 처리하고 있으며, 「개인정보
                    보호법」 제32조에 따라 개인정보파일을 운영하고 있습니다.
                  </Text>
                </Box>
              </VStack>
            )}
          </ModalBody>
          <ModalFooter p={0}>
            <Box w="100%">
              <Button
                onClick={() => {
                  if (termInfo.isTerm) {
                    if (!termInfo.term) {
                      setTermInfo({
                        ...termInfo,
                        term: true,
                      });
                    }
                  } else if (!termInfo.isTerm) {
                    if (!termInfo.personalInfo) {
                      setTermInfo({
                        ...termInfo,
                        personalInfo: true,
                      });
                    }
                  }
                  onClose();
                }}
                bg="#FBBC05"
                h="60px"
                w="100%"
                borderRadius={0}
                _hover={{}}
                _active={{}}
              >
                <HStack>
                  <CustomIcon
                    w="30px"
                    h="30px"
                    name="fillCheckCircle"
                    color="#FFF"
                  />
                  <Text fontWeight={700} fontSize="16px" color="#000">
                    동의
                  </Text>
                </HStack>
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </MobileContentBox>
  );
};

export default MobileSignUpUser;
