import { useRecoilValue } from 'recoil';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import {
  GlobalForm,
  MobileAlbum,
  MobileConvert,
  MobileLogin,
  MobileMain,
  MobileMyPage,
  MobileResult,
  MobileSignUpPet,
  MobileSignUpUser,
} from '.';
import { loadingState, makeImageloadingState } from '../recoil/common';
import { Loading, MakeImageLoading } from '../components';
import { ALBUM, CONVERSION, MAIN, MY_PAGE, SIGN_UP } from '../constant/pageURL';
import AxiosForm from './AxiosForm';
import ImageModal from './ImageModal';

const MobileRouters = () => {
  const globalLoading = useRecoilValue(loadingState);
  const makeImageLoading = useRecoilValue(makeImageloadingState);
  return (
    <BrowserRouter>
      <AxiosForm />
      {globalLoading && <Loading />}
      {makeImageLoading && <MakeImageLoading />}
      <GlobalForm />
      <Routes>
        <Route path="*" element={<Navigate to={MAIN.mobileLogin} replace />} />
        <Route path={MAIN.mobileLogin} element={<MobileLogin />} />
        <Route path={SIGN_UP.mobileUser} element={<MobileSignUpUser />} />
        <Route path={SIGN_UP.mobilePet} element={<MobileSignUpPet />} />
        <Route path={MAIN.mobileRoot} element={<MobileMain />} />
        <Route path={CONVERSION.mobileConversion} element={<MobileConvert />} />
        <Route path={CONVERSION.mobileConvResult} element={<MobileResult />} />
        <Route path={ALBUM.mobileAlbum} element={<MobileAlbum />} />
        <Route path={MY_PAGE.mobileMyPage} element={<MobileMyPage />} />
        <Route path="/image" element={<ImageModal />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MobileRouters;
