import React from 'react';
import { createRoot } from 'react-dom/client';

import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './enviroments/theme/theme';
import App from './App';

const root = createRoot(document.getElementById('root'));
root.render(
  <RecoilRoot>
    <ChakraProvider theme={theme} resetCss={false}>
      {/* <BrowserRouter> */}
      <App />
      {/* </BrowserRouter> */}
    </ChakraProvider>
  </RecoilRoot>,
);
