import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  HStack,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import MobileContentBox from '../../components/layouts/MobileContentBox';
import { CustomIcon } from '../../components';
import MainDog from '../../assets/images/main-dog.svg';
import { REQUIRE_SIGNUP_ERROR, SUCCESS } from '../../constant/errorCode';
import useSign from '../../hook/useSign';
import utils from '../../utils';
import { MAIN, SIGN_UP } from '../../constant/pageURL';
import useModal from '../../hook/useModal';

const MobileLogin = () => {
  const { openModal } = useModal();
  const naviagte = useNavigate();
  const {
    getNormalUserLogin,
    setTempSnsInfo,
    tempAutoLogin,
    setTempAutoLogin,
  } = useSign();

  useEffect(() => {
    if (
      utils.getAutoLogin() === true &&
      utils.isNotEmpty(utils.getSnsInfo().snsToken)
    ) {
      handleNormalUserLogin();
    }
  }, []);

  window.onResultKakao = (data) => {
    if (data.result === 'success') {
      initExLogin(1);
      handleNormalUserLogin(data.id, 1);
    } else {
      openModal({ text: '카카오 로그인에 실패 하였습니다.' });
    }
  };

  window.onResultGoogle = (data) => {
    if (data.result === 'success') {
      initExLogin(2);
      handleNormalUserLogin(data.id, 2);
    } else {
      openModal({ text: '구글 로그인에 실패 하였습니다.' });
    }
  };

  window.onResultApple = (data) => {
    if (data.result === 'success') {
      initExLogin(3);
      handleNormalUserLogin(data.id, 3);
    } else {
      openModal({ text: '애플 로그인에 실패 하였습니다.' });
    }
  };

  const initExLogin = (snsType) => {
    utils.exLogout(snsType);
  };

  const handleNormalUserLogin = async (paramSnsToken, paramSnsType) => {
    const param = {};

    if (utils.getAutoLogin() && utils.isNotEmpty(utils.getSnsInfo().snsToken)) {
      // 자동 로그인
      param.snsToken = utils.getSnsInfo().snsToken;
      param.snsType = utils.getSnsInfo().snsType;
    } else {
      param.snsToken = paramSnsToken;
      param.snsType = paramSnsType;
    }

    const result = await getNormalUserLogin(param);
    if (result.errorCode === SUCCESS) {
      if (
        utils.isEmpty(utils.getAutoLogin()) ||
        utils.getAutoLogin() === false
      ) {
        utils.setAutoLogin(tempAutoLogin);
      }
      naviagte(MAIN.mobileRoot);
    } else if (result.errorCode === REQUIRE_SIGNUP_ERROR) {
      setTempSnsInfo({
        snsToken: paramSnsToken,
        snsType: paramSnsType,
      });
      naviagte(SIGN_UP.mobileUser);
    } else {
      openModal({ text: '로그인에 실패 하였습니다.' });
    }
  };

  const handleSnsLogin = (vendor) => {
    // 1 카카오, 2 구글, 3 애플
    switch (Number(vendor)) {
      case 1:
        // return handleNormalUserLogin(3026809248, 1);
        return utils.exLogin('kakao');
      case 2:
        return utils.exLogin('google');
      case 3:
        return utils.exLogin('apple');
      default:
        openModal({ text: 'SNS를 선택해주세요' });
    }
  };

  return (
    <MobileContentBox bg="#FFD25F">
      <VStack spacing={20} py={10}>
        <Box w="60%">
          <Image src={MainDog} />
        </Box>
        <Box w="80%">
          <VStack>
            <Box w="100%">
              <Button
                onClick={() => {
                  handleSnsLogin(1);
                }}
                borderRadius="6px"
                bg="#FEE500"
                w="100%"
                variant="solid"
                leftIcon={
                  <CustomIcon
                    w="25px"
                    h="25px"
                    name="fillKakaoTalk"
                    color="#181600"
                  />
                }
                _active={{
                  opacity: 0.9,
                }}
                _hover={{
                  opacity: 0.9,
                }}
              >
                <Box w="100%">
                  <Text color="#181600" fontWeight={700} fontSize="18px">
                    카카오 로그인
                  </Text>
                </Box>
              </Button>
            </Box>
            <Box w="100%">
              <Button
                onClick={() => {
                  handleSnsLogin(2);
                }}
                borderRadius="6px"
                bg="#FFF"
                w="100%"
                variant="solid"
                leftIcon={<CustomIcon w="25px" h="25px" name="google" />}
                _active={{
                  opacity: 0.9,
                }}
                _hover={{
                  opacity: 0.9,
                }}
              >
                <Box w="100%">
                  <Text color="#181600" fontWeight={700} fontSize="18px">
                    구글 로그인
                  </Text>
                </Box>
              </Button>
            </Box>
            <Box w="100%">
              <Button
                onClick={() => {
                  handleSnsLogin(3);
                }}
                borderRadius="6px"
                bg="#000"
                w="100%"
                variant="solid"
                leftIcon={
                  <CustomIcon w="25px" h="25px" name="fillApple" color="#FFF" />
                }
                _active={{
                  opacity: 0.9,
                }}
                _hover={{
                  opacity: 0.9,
                }}
              >
                <Box w="100%">
                  <Text color="#FFF" fontWeight={700} fontSize="18px">
                    애플 로그인
                  </Text>
                </Box>
              </Button>
            </Box>
            <Box w="100%">
              <HStack pt="3">
                <Box>
                  <Checkbox
                    isChecked={tempAutoLogin}
                    onChange={() => {
                      setTempAutoLogin(!tempAutoLogin);
                    }}
                  />
                </Box>
                <Box>
                  <Text fontWeight={500} fontSize="16px" color="#000920">
                    로그인 유지
                  </Text>
                </Box>
              </HStack>
            </Box>
          </VStack>
        </Box>
      </VStack>
    </MobileContentBox>
  );
};

export default MobileLogin;
