import { useEffect, useState, useCallback } from 'react';
import {
  Avatar,
  Box,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import MobileContentBox from '../../components/layouts/MobileContentBox';
import { CustomIcon, MobileHeader } from '../../components';
import useAlbumHistory from '../../hook/useAlbumHistory';
import utils from '../../utils';
import MainDog from '../../assets/images/main-dog.svg';
import DogTab from '../../assets/images/dog-tab.svg';
import CatTab from '../../assets/images/cat-tab.svg';
import useModal from '../../hook/useModal';
import MobileShareDrawer from '../../components/custom/common/MobileShareDrawer';

const MobileAlbum = () => {
  const { openModal } = useModal();
  const [errorIds, setErrorIds] = useState([]);
  const { albumHistory, listAlbumHistory, getListAlbumHistory } =
    useAlbumHistory();

  const [selectAlbum, setSelctAlBum] = useState(albumHistory);

  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();

  const {
    isOpen: isOpenImage,
    onOpen: onOpenImage,
    onClose: onCloseImage,
  } = useDisclosure();

  useEffect(() => {
    getListAlbumHistory();
  }, []);

  /*
  const [isBottom, setIsBottom] = useState(false);

  const [isScroll, setIsScroll] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    if (isBottom) {
      console.log(
        '스크롤이 페이지 하단에 도달하여 페이지 갱신 처리를 수행합니다.',
      );
      setAlbumHistoryPage({
        ...albumHistoryPage,
        pageNum: albumHistoryPage.pageNum + 1,
      });
    }
  }, [isBottom]);

  const handleScroll = () => {
    const { scrollTop } = document.documentElement;
    const windowHeight = window.innerHeight;
    const fullHeight = document.documentElement.scrollHeight;

    // console.log(scrollTop + windowHeight);
    // console.log(fullHeight);
    if (scrollTop + windowHeight === fullHeight) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };
  */

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [modalImageUrl, setModalImageUrl] = useState('');
  const [shareUrl, setShareUrl] = useState('');
  const [shareText, setShareText] = useState('');
  const [shareId, setShareId] = useState('');

  const handleAlbumName = useCallback((album) => {
    const { trackType, trackTwoName } = album;
    const petName = album.name;
    const trackStyleValue = utils.parseTrackStyleValue(album.trackOneStyle);
    if (trackType === 1) {
      return (
        <HStack spacing={1}>
          <Text fontWeight={700} fontSize="16px" color="#444">
            {trackStyleValue}
          </Text>
          <Text fontWeight={700} fontSize="16px" color="#4285F4">
            {petName}
          </Text>
        </HStack>
      );
    }
    return (
      <HStack spacing={1}>
        <Text fontWeight={700} fontSize="16px" color="#34A853">
          {trackTwoName}
        </Text>
        <Text fontWeight={700} fontSize="16px" color="#444">
          를 닮은
        </Text>
        <Text fontWeight={700} fontSize="16px" color="#4285F4">
          {petName}
        </Text>
      </HStack>
    );
  });

  const handleOnErrorImage = (id) => {
    errorIds.push(id);
    setErrorIds(errorIds);
  };

  const handleDetailPet = (album) => {
    const temp = errorIds.find((id) => {
      return id === album.albumHistoryId;
    });
    if (temp) {
      openModal({ text: '해당 이미지를 찾을 수 없습니다.\n' });
      return;
    }
    setSelctAlBum(album);
    setShareUrl(utils.handleAlbumImage(album.albumHistoryId));
    setShareId(album.albumHistoryId);
    let tempShareText = '';
    if (album.trackType === 1) {
      tempShareText = `${utils.parseTrackStyleValue(album.trackOneStyle)} ${
        album.name
      }`;
    }
    if (album.trackType === 2) {
      tempShareText = `${album.trackTwoName} 닮은 ${album.name}`;
    }
    setShareText(tempShareText);
    onOpen();
  };

  return (
    <MobileContentBox>
      <Box w="100%">
        <VStack spacing={0}>
          <MobileHeader title="내 반려앨범" isReplace />
        </VStack>
      </Box>
      <Box w="100%" px="20px">
        <VStack spacing={0}>
          {listAlbumHistory.map((album, index) => {
            const key = `album_${index}`;
            return (
              <Box
                w="100%"
                key={key}
                borderBottom={
                  index === listAlbumHistory.length - 1
                    ? ''
                    : '1px solid #EEEEEE'
                }
                onClick={() => {
                  handleDetailPet(album);
                }}
              >
                <HStack
                  justifyContent="space-between"
                  alignItems="center"
                  py={2}
                >
                  <Box>
                    <VStack>
                      <Box w="100%">{handleAlbumName(album)}</Box>
                      <Box>
                        <Text fontWeight={400} fontSize="15px" color="#999">
                          {utils.parseDateToStr(album.createdTime, '.', true)}
                        </Text>
                      </Box>
                      <Box w="100%" h="24px">
                        <Image src={album.type === 1 ? DogTab : CatTab} />
                      </Box>
                    </VStack>
                  </Box>
                  <Box>
                    <Image
                      border="1px solid rgba(0, 0, 0, 0.10)"
                      borderRadius="8px"
                      src={utils.handleAlbumImage(album.albumHistoryId)}
                      onError={(e) => {
                        // console.log(e.target.src, album.albumHistoryId);
                        handleOnErrorImage(album.albumHistoryId);
                      }}
                      w="100px"
                      h="100px"
                      fallbackSrc={MainDog}
                    />
                  </Box>
                </HStack>
              </Box>
            );
          })}
          {listAlbumHistory.length === 0 && (
            <Box>
              <Text>등록된 반려앨범이 없습니다.</Text>
            </Box>
          )}
        </VStack>
      </Box>

      {isOpen && (
        <Modal onClose={onClose} size="full" isOpen={isOpen}>
          <ModalOverlay />
          <ModalContent h="100%">
            <MobileHeader
              title="내 반려앨범"
              onClick={() => {
                onClose();
              }}
            />
            <ModalBody h="100%" p={0}>
              <Box
                className="center-cropped"
                position="relative"
                w="100%"
                h="calc(100% - 165px)"
              >
                <Image
                  onClick={() => {
                    const url = utils.handleAlbumImage(
                      selectAlbum.albumHistoryId,
                      3,
                    );
                    setModalImageUrl(url);
                    onOpenImage();
                  }}
                  w="100%"
                  h="100%"
                  src={`${utils.handleAlbumImage(selectAlbum.albumHistoryId)}`}
                  overflow="hidden"
                  fallback={
                    <HStack w="100%" h="100%" justifyContent="center">
                      <Spinner
                        w="40px"
                        h="40px"
                        thickness="6px"
                        speed="0.65s"
                        color="gray.500"
                        size="xl"
                      />
                    </HStack>
                  }
                />
                <Box w="100%" zIndex={15} position="absolute" bottom="0px">
                  {selectAlbum.trackOneStyle !== 0 && (
                    <HStack justifyContent="center">
                      <Box>
                        <Avatar
                          onClick={() => {
                            const url = utils.handleAlbumImage(
                              selectAlbum.albumHistoryId,
                              1,
                            );
                            setModalImageUrl(url);
                            onOpenImage();
                          }}
                          border="1px solid"
                          borderColor="#FBBC05"
                          boxSizing="border-box"
                          w="80px"
                          h="80px"
                          bg="#FBBC05"
                          src={`${utils.handleAlbumImage(
                            selectAlbum.albumHistoryId,
                            1,
                          )}`}
                        />
                      </Box>
                    </HStack>
                  )}
                  {selectAlbum.trackType === 2 && (
                    <HStack justifyContent="center">
                      <HStack
                        bg="rgba(0, 0, 0, 0.7)"
                        p="10px"
                        borderRadius="300px"
                      >
                        <Box>
                          <Avatar
                            onClick={() => {
                              const url = utils.handleAlbumImage(
                                selectAlbum.albumHistoryId,
                                1,
                              );
                              setModalImageUrl(url);
                              onOpenImage();
                            }}
                            border="1px solid"
                            borderColor="#FBBC05"
                            boxSizing="border-box"
                            w="80px"
                            h="80px"
                            bg="#FBBC05"
                            src={`${utils.handleAlbumImage(
                              selectAlbum.albumHistoryId,
                              1,
                            )}`}
                          />
                        </Box>
                        <Box>
                          <CustomIcon
                            w="30px"
                            h="30px"
                            name="plus"
                            color="#FFF"
                          />
                        </Box>
                        <Box>
                          <Avatar
                            onClick={() => {
                              const url = utils.handleAlbumImage(
                                selectAlbum.albumHistoryId,
                                2,
                              );
                              setModalImageUrl(url);
                              onOpenImage();
                            }}
                            border="1px solid"
                            borderColor="#FBBC05"
                            boxSizing="border-box"
                            w="80px"
                            h="80px"
                            bg="#FBBC05"
                            src={`${utils.handleAlbumImage(
                              selectAlbum.albumHistoryId,
                              2,
                            )}`}
                          />
                        </Box>
                      </HStack>
                    </HStack>
                  )}
                </Box>
              </Box>
              <Box
                position="fixed"
                bottom="10px"
                w="100%"
                h="165px"
                bg="#FFF"
                py={6}
              >
                <VStack>
                  <Box py={1}>
                    {selectAlbum.trackType === 1 && (
                      <HStack spacing={1}>
                        <Text fontWeight={700} fontSize="16px" color="#222">
                          MungTok AI가 변환한
                        </Text>
                        <Text fontWeight={700} fontSize="16px" color="#EA4335">
                          {utils.parseTrackStyleValue(
                            selectAlbum.trackOneStyle,
                          )}
                        </Text>
                        <Text fontWeight={700} fontSize="16px" color="#4285F4">
                          {selectAlbum.name}
                        </Text>
                        <Text fontWeight={700} fontSize="16px" color="#222">
                          입니다
                        </Text>
                      </HStack>
                    )}
                    {selectAlbum.trackType === 2 && (
                      <VStack>
                        <Box>
                          <Text fontWeight={700} fontSize="16px" color="#222">
                            MungTok AI가 변환한
                          </Text>
                        </Box>
                        <Box>
                          <HStack spacing={1}>
                            <Text
                              fontWeight={700}
                              fontSize="16px"
                              color="#EA4335"
                            >
                              {selectAlbum.trackTwoName}
                            </Text>
                            <Text fontWeight={700} fontSize="16px" color="#222">
                              닮은
                            </Text>
                            <Text
                              fontWeight={700}
                              fontSize="16px"
                              color="#4285F4"
                            >
                              {selectAlbum.name}
                            </Text>
                            <Text fontWeight={700} fontSize="16px" color="#222">
                              입니다
                            </Text>
                          </HStack>
                        </Box>
                      </VStack>
                    )}
                  </Box>
                  {/* 공유 하기 */}
                  <Box pt={1}>
                    <Avatar
                      onClick={onOpenDrawer}
                      bg="#FBBC05"
                      icon={
                        <Box w="25px" h="25px" pr="2px">
                          <CustomIcon name="fillShare" color="black" />
                        </Box>
                      }
                    />
                  </Box>
                </VStack>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {isOpenImage && (
        <Modal onClose={onCloseImage} size="full" isOpen={isOpenImage}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton onClick={onCloseImage} color="#FFF" />
            <ModalBody h="100%" p={0} bg="black">
              <HStack h="100vh" justifyContent="center" alignItems="center">
                <Image
                  src={modalImageUrl}
                  fallback={
                    <Spinner
                      w="100px"
                      h="100px"
                      thickness="6px"
                      speed="0.65s"
                      color="gray.200"
                      size="xl"
                    />
                  }
                />
              </HStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {isOpenDrawer && (
        <MobileShareDrawer
          isOpen={isOpenDrawer}
          url={shareUrl}
          text={shareText}
          id={shareId}
          onClose={() => {
            onCloseDrawer();
          }}
        />
      )}
    </MobileContentBox>
  );
};

export default MobileAlbum;
