import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import MobileContentBox from '../../components/layouts/MobileContentBox';
import {
  MobileHeader,
  MobilePetInfoCard,
  MobileRegisterDrawer,
} from '../../components';

import usePet from '../../hook/usePet';
import utils from '../../utils';
import useModal from '../../hook/useModal';
import { userInfoState } from '../../recoil/userInfoRecoil';

const MobileMyPage = () => {
  const userInfo = useRecoilValue(userInfoState);
  const { openModal } = useModal();

  const [isModify, setIsModify] = useState(false);

  const {
    isPrevData,
    listPet,
    getListPet,
    deletePet,
    resetPrevPet,
    handleSetPrevPet,
  } = usePet();

  useEffect(() => {
    getListPet();
  }, []);

  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();

  const handleDelete = async (pet) => {
    const result = await deletePet(pet);
    if (result) {
      getListPet();
    }
  };

  const handleModify = (pet) => {
    setIsModify(true);
    handleSetPrevPet(pet);
    onOpenDrawer();
  };

  const handleRegister = () => {
    setIsModify(false);
    if (isPrevData()) {
      openModal({
        type: 'confirm',
        text: '임시저장 된 정보가 있습니다\n적용 하시겠습니까?',
        onAgreeText: '적용',
        onAgree: () => {
          onOpenDrawer();
        },
        onCancelText: '새로작성',
        onCancel: () => {
          resetPrevPet();
          onOpenDrawer();
        },
      });
    } else {
      onOpenDrawer();
    }
  };

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <>
      <MobileContentBox>
        <Box w="100%">
          <VStack spacing={0}>
            <MobileHeader title="마이페이지" isLogout />
          </VStack>
        </Box>
        <Box w="100%">
          <HStack spacing={0}>
            <Tabs
              w="100%"
              variant="toggleButton"
              defaultIndex={tabIndex}
              onChange={(index) => {
                setTabIndex(index);
              }}
            >
              <TabList w="100%">
                <Tab w="50%">
                  <Text color={tabIndex === 0 ? '#222' : '#666'}>
                    반려동물 정보
                  </Text>
                </Tab>
                <Tab w="50%">
                  <Text color={tabIndex === 1 ? '#222' : '#666'}>
                    반려인 정보
                  </Text>
                </Tab>
              </TabList>
              <TabPanels px="20px" pb="80px">
                <TabPanel p="0" pt="20px">
                  <VStack spacing={0}>
                    <Box w="100%">
                      <VStack spacing={3}>
                        {listPet.length > 0 &&
                          listPet.map((pet, index) => {
                            const key = `pet_${index}`;
                            return (
                              <Box key={key} w="100%">
                                <MobilePetInfoCard
                                  pet={pet}
                                  onClickDelete={(pet) => {
                                    openModal({
                                      type: 'confirm',
                                      text: `${pet.name} 정보를 삭제 하시겠습니까?`,
                                      onAgree: () => {
                                        handleDelete(pet);
                                      },
                                    });
                                  }}
                                  onClickEdit={(pet) => {
                                    handleModify(pet);
                                  }}
                                />
                              </Box>
                            );
                          })}
                      </VStack>
                      {listPet.length === 0 && (
                        <VStack spacing={3}>
                          <Box w="100%">
                            <Text textAlign="center">
                              등록한 반려동물이 없습니다.
                            </Text>
                          </Box>
                        </VStack>
                      )}
                    </Box>
                  </VStack>
                </TabPanel>
                <TabPanel p="0">
                  <VStack spacing={2}>
                    <Box w="100%">
                      <HStack
                        h="50px"
                        justifyContent="space-between"
                        alignItems="center"
                        borderBottom="1px solid"
                        borderColor="#ddd"
                      >
                        <Box>
                          <Text fontWeight={500} fontSize="15px" color="#444">
                            반려인 성함
                          </Text>
                        </Box>
                        <Box>
                          <Text fontWeight={700} fontSize="15px" color="#222">
                            {userInfo.name}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                    <Box w="100%">
                      <HStack
                        h="50px"
                        justifyContent="space-between"
                        alignItems="center"
                        borderBottom="1px solid"
                        borderColor="#ddd"
                      >
                        <Box>
                          <Text fontWeight={500} fontSize="15px" color="#444">
                            반려인 성별
                          </Text>
                        </Box>
                        <Box>
                          <Text fontWeight={700} fontSize="15px" color="#222">
                            {userInfo.sex === 1 ? '남성' : '여성'}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                    <Box w="100%">
                      <HStack
                        h="50px"
                        justifyContent="space-between"
                        alignItems="center"
                        borderBottom="1px solid"
                        borderColor="#ddd"
                      >
                        <Box>
                          <Text fontWeight={500} fontSize="15px" color="#444">
                            반려인 생일
                          </Text>
                        </Box>
                        <Box>
                          <Text fontWeight={700} fontSize="15px" color="#222">
                            {utils.parseDateToStr(userInfo.birth, '.')}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                  </VStack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </HStack>
        </Box>
        <Box position="fixed" bottom={0} w="100%">
          <Button
            onClick={handleRegister}
            bg="#34A853"
            h="60px"
            w="100%"
            borderRadius={0}
            _hover={{}}
            _active={{}}
          >
            <Text fontWeight={700} fontSize="16px" color="#FFF">
              다른 친구가 있나요?
            </Text>
          </Button>
        </Box>
      </MobileContentBox>

      {isOpenDrawer && (
        <MobileRegisterDrawer
          isModify={isModify}
          isOpen={isOpenDrawer}
          onClose={() => {
            resetPrevPet();
            onCloseDrawer();
          }}
        />
      )}
    </>
  );
};

export default MobileMyPage;
