import {
  Avatar,
  Box,
  Button,
  HStack,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import MobileContentBox from '../../components/layouts/MobileContentBox';

import DogAvatar from '../../assets/images/dog-avatar.svg';
import DogAlbum from '../../assets/images/dog-album.svg';
import MungtokTitle from '../../assets/images/mungtok-title.svg';
import { trackStyleState } from '../../recoil/common';
import { MobileTrackForm } from '../../components';
import { ALBUM, CONVERSION, MY_PAGE } from '../../constant/pageURL';

const MobileMain = () => {
  const setTrackStyle = useSetRecoilState(trackStyleState);
  const navigate = useNavigate();
  return (
    <MobileContentBox p="20px" bg="#FFD25F">
      <VStack>
        <Box h="60px" w="100%">
          <HStack justifyContent="space-between" alignItems="center">
            <Box w="48px" />
            <Box>
              <Image src={MungtokTitle} />
            </Box>
            <Box w="48px">
              <Avatar
                src={DogAvatar}
                onClick={() => {
                  navigate(MY_PAGE.mobileMyPage);
                }}
              />
            </Box>
          </HStack>
        </Box>

        <Box h="calc(100vh - 200px)" w="100%">
          <VStack spacing={4}>
            <MobileTrackForm
              petType={1}
              trackStyle={1}
              onClick={() => {
                setTrackStyle(1);
                navigate(CONVERSION.mobileConversion);
              }}
            />
            <MobileTrackForm
              petType={2}
              trackStyle={0}
              onClick={() => {
                setTrackStyle(0);
                navigate(CONVERSION.mobileConversion);
              }}
            />
          </VStack>
        </Box>

        <Box w="100%">
          <Button
            onClick={() => {
              navigate(ALBUM.mobileAlbum);
            }}
            borderRadius="14px"
            bg="#FFD25F"
            borderColor="#222222"
            border="2px solid"
            w="100%"
            h="80px"
            _hover={{}}
            _active={{}}
          >
            <Image src={DogAlbum} mr={2} />
            <Text fontWeight={700} fontSize="19px">
              내 반려앨범
            </Text>
          </Button>
        </Box>
      </VStack>
    </MobileContentBox>
  );
};

export default MobileMain;
