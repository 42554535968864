import api from './api';

const BASE = '/pet';

const deletePet = async (data) => {
  const result = await api.delete(`/pet`, {
    token: true,
    body: data,
  });
  return result.data;
};

const getListPet = async (data) => {
  const result = await api.get(`${BASE}/listPet`, {
    token: true,
    body: data,
  });
  return result.data;
};

const postPetModifyPet = async (data) => {
  const result = await api.post(`${BASE}/modifyPet`, {
    token: true,
    body: data,
  });
  return result.data;
};

const postPet = async (data) => {
  const result = await api.post(BASE, {
    token: true,
    body: data,
  });
  return result.data;
};

export default {
  deletePet,
  getListPet,
  postPetModifyPet,
  postPet,
};
