import { Box, HStack, VStack, Text } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { Image } from '@chakra-ui/react';
import Cat from '../../assets/images/cat-white.svg';

const MakeImageLoading = () => {
  return (
    <Box
      position="absolute"
      zIndex={99999}
      w="100%"
      h="100vh"
      bgColor="blackAlpha.600"
    >
      <HStack justifyContent="center" alignItems="center" h="100%">
        <Box>
          <VStack>
            <Box position="relative">
              <Spinner
                w="170px"
                h="170px"
                thickness="6px"
                speed="0.65s"
                color="gray.200"
                size="xl"
              />
              <Image
                position="absolute"
                w="85px"
                h="103px"
                top="calc(100% - 140px)"
                left="calc(100% - 130px)"
                src={Cat}
              />
            </Box>
            <Box>
              <Text fontWeight={700} fontSize="18px" color="#FFF">
                MungTok AI가
              </Text>
            </Box>
            <Box>
              <Text fontWeight={700} fontSize="18px" color="#FFF">
                반려동물을 변신 시키고 있습니다.
              </Text>
            </Box>
            <Box>
              <Text fontWeight={700} fontSize="18px" color="#FFF">
                잠시만 기다려주세요.
              </Text>
            </Box>
          </VStack>
        </Box>
      </HStack>
    </Box>
  );
};

export default MakeImageLoading;
