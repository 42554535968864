import { Box, Button, Image, Text, VStack } from '@chakra-ui/react';
import CatMain from '../../../assets/images/cat-main.svg';
import DogMain from '../../../assets/images/dog-main.svg';

const MobileTrackForm = (props) => {
  const { petType, trackStyle } = props;
  const { onClick } = props;

  const handleTrackStyle = () => {
    return trackStyle !== 0 ? (
      <VStack spacing={0}>
        <Box w="100%">
          <Text fontWeight={700} fontSize="19px" color="#222">
            내 반려동물이
          </Text>
        </Box>
        <Box w="100%">
          <Text fontWeight={700} fontSize="19px" color="#222">
            사람이라면?
          </Text>
        </Box>
      </VStack>
    ) : (
      <VStack spacing={0}>
        <Box w="100%">
          <Text fontWeight={700} fontSize="19px" color="#222">
            내 반려동물이 나를
          </Text>
        </Box>
        <Box w="100%">
          <Text fontWeight={700} fontSize="19px" color="#222">
            닮은 사람이라면?
          </Text>
        </Box>
      </VStack>
    );
  };

  return (
    <Box w="100%" h="200px" bg="#FFF" borderRadius="14px" position="relative">
      {/* 좌상 */}
      <Box position="relative" top={5} left={5}>
        <Box position="absolute">{handleTrackStyle()}</Box>

        <Box position="absolute" top="80px">
          <Button
            bg="#222222"
            borderRadius="10px"
            w="100px"
            h="40px"
            _hover={{}}
            _active={{
              opacity: 0.7,
            }}
            onClick={onClick}
          >
            <Text color="#FFFFFF" fontWeight={700} fontSize="14px">
              알아보기
            </Text>
          </Button>
        </Box>
      </Box>

      {/* 우하 */}
      <Box position="absolute" bottom={5} right={5}>
        <Image src={petType === 1 ? CatMain : DogMain} />
      </Box>
    </Box>
  );
};

export default MobileTrackForm;
