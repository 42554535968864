import { useState, useEffect } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { FORM_MIN_WIDTH_PX } from '../../constant/common';
import Sidebar from './Sidebar';
import useSignAdmin from '../../hook/useSignAdmin';
import { MAIN } from '../../constant/pageURL';

const ContentBox = (props) => {
  const navigate = useNavigate();
  const { children, minW = FORM_MIN_WIDTH_PX } = props;
  const { adminUser } = useSignAdmin();
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    if (adminUser.isLogin === false) {
      navigate(MAIN.adminLogin, { replace: true });
    } else {
      setIsLogin(true);
    }
  }, []);

  return (
    <ContentStyledBox
      bg="#F1F1F1"
      h="100vh"
      width="100%"
      minW={minW}
      // position="relative"
      // overflow="auto"
      position="relative"
      overflow="auto"
      // paddingBottom="30px"
    >
      <HStack justifyContent="space-between" spacing={0} h="100%">
        {isLogin && <Sidebar className="Sidebar" />}
        <Box h="100%" w="100%">
          {children}
        </Box>
      </HStack>
    </ContentStyledBox>
  );
};

export default ContentBox;

const ContentStyledBox = styled(Box)({
  // width: 'calc(100% - 260px)',
});
