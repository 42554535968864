import {
  Text,
  VStack,
  ListItem,
  List,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Image,
  Spacer,
  Flex,
  Box,
  Link,
  AccordionIcon,
} from '@chakra-ui/react';
import {
  NavLink as RouterLink,
  useLocation,
  useNavigate, // <-- import the NavLink component
} from 'react-router-dom';

// import { ColorModeSwitcher } from "./ColorModeSwitcher";
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import CustomIcon from '../icons/CustomIcon';

import { useAxiosHandler } from '../../hook/useAxiosHandler';
// import { navigationUrlState } from '../../recoil/common';
import utils from '../../utils';
import useSign from '../../hook/useSign';
import { ALBUM, MEMBER } from '../../constant/pageURL';

const Sidebar = () => {
  const { removeUserInfo } = useSign();
  // const bizUser = useRecoilValue(bizUserState);

  const navigate = useNavigate();
  const location = useLocation();
  // const [navigationUrl, setNavigationUrl] = useRecoilState(navigationUrlState);

  /*
  useEffect(() => {
    setNavigationUrl(location.pathname);
  }, [location]);

  useEffect(() => {
    if (utils.isEmpty(utils.getAccessToken()) || !bizUser.isLogin) {
      removeUserInfo();
      navigate(MAIN.login);
    }
  }, [navigationUrl]);

  const handleAccordion0 = () => {
    if (accordionBtn0.length === 1) {
      setAccordionBtn0([]);
    } else {
      setAccordionBtn0([0]);
    }
  };
  */

  return (
    <Box>
      <SidebarBody
        flexDirection="column"
        w="260px"
        h="100vh"
        overflow="scroll"
        overflowX="hidden"
        backgroundColor="#000920"
        position="relative"
      >
        {/* 상단 */}
        <VStack
          marginTop="33px"
          marginBottom="42px"
          alignItems="center"
          spacing="0px"
          w="100%"
        >
          <Box>
            <Text fontWeight={500} fontSize="20px" color="#FFF">
              멍톡 관리자
            </Text>
          </Box>
        </VStack>

        {/* 중간 */}
        <VStack align="stretch">
          <Box justifyContent="end" display="flex">
            <nav style={{ width: '240px' }}>
              <Accordion allowToggle color="#CCD2E3">
                <AccordionItem border="none">
                  <NavLink to={MEMBER.adminMember} as={RouterLink}>
                    <AccordionButton>
                      <Text>회원</Text>
                    </AccordionButton>
                  </NavLink>
                </AccordionItem>
              </Accordion>

              <Accordion allowToggle color="#CCD2E3">
                <AccordionItem border="none">
                  <NavLink to={ALBUM.adminAlbum} as={RouterLink}>
                    <AccordionButton>
                      <Text>반려앨범</Text>
                    </AccordionButton>
                  </NavLink>
                </AccordionItem>
              </Accordion>
            </nav>
          </Box>
        </VStack>
      </SidebarBody>
    </Box>
  );
};
export default Sidebar;

const SidebarBody = styled(Flex)`
  &::-webkit-scrollbar {
    display: none;
  }
`;

const NavLink = styled('Link')({
  display: 'flex',
  alignItems: 'center',
  height: '56px',
  borderTopLeftRadius: '8px',
  borderBottomLeftRadius: '8px',
});
