import { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import useModal from './useModal';
import { userInfoState } from '../recoil/userInfoRecoil';
import { MAIN } from '../constant/pageURL';
import { SUCCESS } from '../constant/errorCode';
import adminUserApi from '../apis/adminUserApi';
import utils from '../utils';

const useSignAdmin = () => {
  const resetAdminUser = useResetRecoilState(userInfoState);

  const [adminUser, setAdminUserInfo] = useRecoilState(userInfoState);
  const navigate = useNavigate();
  const { openModal } = useModal();

  const loginAdminUser = useCallback(({ id }) => {
    setAdminUserInfo({
      ...adminUser,
      id,
      isLogin: true,
    });
  });

  const logoutAdminUser = useCallback(async (massage) => {
    await adminUserApi.postAdminUserLogout();
    openModal({
      text: massage || '로그아웃 되었습니다.',
      onAgree: () => {
        logoutUserProcess();
        navigate(MAIN.mobileLogin);
      },
    });
  });

  const getAdminUserLogin = useCallback(async (data) => {
    const param = {
      id: data.id,
      pw: data.pw,
    };
    const result = await adminUserApi.getAdminUserLogin(param);
    if (result.errorCode === SUCCESS) {
      loginAdminUser({
        id: data.id,
      });
      utils.setAccessToken(result.data.accessToken);
      utils.setRefreshToken(result.data.refreshToken);
      return true;
    }
    return false;
  });

  const logoutUserProcess = useCallback(() => {
    resetAdminUserProcess();
  });

  const resetAdminUserProcess = useCallback(() => {
    resetAdminUser();
    utils.setAccessToken('');
    utils.setRefreshToken('');
  });

  return {
    adminUser,
    logoutUserProcess,
    loginAdminUser,
    logoutAdminUser,
    getAdminUserLogin,
    resetAdminUser,
    resetAdminUserProcess,
  };
};

export default useSignAdmin;
