import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import utils from '../../utils';

const MobileContentBox = (props) => {
  const { children } = props;
  const { bg = '#FFF', pb, p = '0px' } = props;
  return (
    <ContentStyledBox
      bg={bg}
      pb={pb}
      p={utils.isEmpty(pb) ? p : ''}
      h="100vh"
      position="relative"
      overflow="auto"
    >
      {children}
    </ContentStyledBox>
  );
};

export default MobileContentBox;

const ContentStyledBox = styled(Box)({
  width: 'calc(100%)',
});
