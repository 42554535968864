import { useEffect, useState, useCallback } from 'react';
import {
  Avatar,
  Box,
  HStack,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import MainDog from '../../../assets/images/main-dog.svg';
import ContentBox from '../../../components/layouts/ContentBox';
import Navbar from '../../../components/layouts/Navbar';
import { DefaultPaginate, SearchInput } from '../../../components';
import { albumIdState } from '../../../recoil/userInfoRecoil';
import { DEFAULT_CONTENT_NUM } from '../../../constant/common';
import adminUserApi from '../../../apis/adminUserApi';
import utils from '../../../utils';
import { NO_DATA_ERROR, SUCCESS } from '../../../constant/errorCode';
import { ALBUM } from '../../../constant/pageURL';
import { API_URL } from '../../../constant/enviroment';

const AlbumMain = () => {
  const navigate = useNavigate();
  const [albumId, setAlbumId] = useRecoilState(albumIdState);
  const formHeight = 627;

  const [pageCount, setPageCount] = useState(1);
  const [searchBy, setSearchBy] = useState('');
  const [totalCount, setTotalCount] = useState(1);
  const contentNum = DEFAULT_CONTENT_NUM;

  const [datas, setDatas] = useState([
    /*
    {
      name: '솔이',
      type: 1,
      conversionFilePath:
        '/home/supr/aai/GP-UNIT/data/front_test/input_user/content_user/15030242878_1/9e6dbe1a-7bdd-4b18-adc8-416b1dcae7e6.jpg',
      sourceFilePath:
        '/home/supr/aai/GP-UNIT/data/front_test/input_user/content_user/15030242878_1/9e6dbe1a-7bdd-4b18-adc8-416b1dcae7e6.jpg',
      trackType: 1,
      albumHistoryId: 1,
      trackOneStyle: 1,
      trackTwoFilePath:
        '/home/supr/aai/GP-UNIT/data/front_test/input_user/content_user/15030242878_1/9e6dbe1a-7bdd-4b18-adc8-416b1dcae7e6.jpg',
      createdTime: '2023-09-16T21:46:29.963Z',
      trackTwoName: '홍길동',
    },
    */
  ]);

  const headers = [
    { title: '번호', minWidth: '90px', width: 'auto' },
    { title: '반려인명', minWidth: '70px', width: 'auto' },
    { title: '타입', minWidth: '80px', width: 'auto' },
    { title: '반려동물', minWidth: '70px', width: 'auto' },
    { title: '결과이미지', minWidth: '120px', width: 'auto' },
    { title: '시행일시', minWidth: '120px', width: 'auto' },
  ];

  useEffect(() => {
    getListAlbumHistory();
  }, []);

  const getListAlbumHistory = async () => {
    const param = {
      pageNum: pageCount,
      contentNum,
    };
    if (utils.isNotEmpty(searchBy)) {
      param.searchBy = searchBy.trim();
    }
    const result = await adminUserApi.getListAlbumHistory(param);
    if (result.errorCode === SUCCESS) {
      setDatas(result.datas);
      setTotalCount(result.totalCount);
    } else if (result.errorCode === NO_DATA_ERROR) {
      setDatas([]);
      setTotalCount(1);
    }
  };

  const makeHeader = () => {
    return headers.map((element, index) => {
      const key = `${index}_key`;
      return (
        <Th
          key={key}
          minW={datas.length === 0 ? 'auto' : element.minWidth}
          w={element.width}
        >
          <Text>{element.title}</Text>
        </Th>
      );
    });
  };

  const handleOnClickRow = (data) => {
    // setNormlaUserId(data.albumId);
    setAlbumId(data.albumHistoryId);
    navigate(ALBUM.adminAlbumDetail);
  };

  const handleOnErrorImage = (id) => {
    console.log(id);
    /*
    errorIds.push(id);
    setErrorIds(errorIds);
    */
  };

  const handleTrackType = (type) => {
    // 1:cool, 2:cute, 3:dandy, 4:handsome, 5:pretty
    switch (type) {
      case 1:
        return 'cool';
      case 2:
        return 'cute';
      case 3:
        return 'dandy';
      case 4:
        return 'handsome';
      case 5:
        return 'pretty';
      default:
        return '해당사항 없음';
    }
  };

  return (
    <ContentBox>
      <Navbar title="반려앨범 관리" />

      <Box className="form-box">
        <Box px="20px">
          <HStack className="form-header" justifyContent="end">
            <Box>
              <Text fontWeight={500} fontSize="16px">
                반려인 검색
              </Text>
            </Box>
            <Box>
              <SearchInput
                value={searchBy}
                onChange={(e) => {
                  setSearchBy(e.target.value);
                }}
                onClick={() => {
                  getListAlbumHistory();
                }}
              />
            </Box>
          </HStack>
        </Box>
        <Box className="form-body" h={formHeight}>
          <TableContainer
            style={{ marginTop: '0px' }}
            maxW="100%"
            h="100%"
            className="default-table"
          >
            <Table>
              <Thead>
                <Tr>{makeHeader()}</Tr>
              </Thead>
              <Tbody>
                {datas.length > 0 ? (
                  datas.map((data, bodyIndex) => {
                    const key = `td_${bodyIndex}`;
                    return (
                      <Tr
                        key={key}
                        _hover={{
                          cursor: 'pointer',
                          bg: 'rgba(245, 247, 251, 1)',
                        }}
                        onClick={() => {
                          handleOnClickRow(data);
                        }}
                      >
                        <Td>
                          <Text>{datas.length - bodyIndex}</Text>
                        </Td>
                        <Td>
                          <Text>{data.name}</Text>
                        </Td>
                        <Td>
                          {/* <Text>{handleTrackType(data.trackType)}</Text> */}
                          <Text>{data.trackType}</Text>
                        </Td>
                        <Td>
                          <Text>{data.petName}</Text>
                        </Td>
                        <Td>
                          <HStack justifyContent="center" alignItems="center">
                            <Box>
                              <Image
                                boxSize="40px"
                                border="1px solid rgba(0, 0, 0, 0.10)"
                                borderRadius="50%"
                                src={utils.handleAlbumImage(
                                  data.albumHistoryId,
                                )}
                                onError={(e) => {
                                  // console.log(e.target.src, album.albumHistoryId);
                                  handleOnErrorImage(data.albumHistoryId);
                                }}
                                fallbackSrc={MainDog}
                              />
                            </Box>
                          </HStack>
                        </Td>
                        <Td>
                          <Text>
                            {utils.parseDateToStr(
                              data.createdTime,
                              '-',
                              true,
                              ':',
                            )}
                          </Text>
                        </Td>
                      </Tr>
                    );
                  })
                ) : (
                  <Tr
                    style={{
                      height: '50px',
                    }}
                  >
                    <Td colSpan={headers.length}>
                      <Text
                        style={{
                          fontWeight: 700,
                          fontSize: '15px',
                        }}
                      >
                        등록된 반려 앨범 정보가 없습니다
                      </Text>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <HStack className="form-footer" justifyContent="space-between">
          <Box>
            <DefaultPaginate pageCount={pageCount} />
          </Box>
        </HStack>
      </Box>
    </ContentBox>
  );
};

export default AlbumMain;
