export const execute = (action, param) => {
  return window.M.execute(action, param);
};

export const exLogin = (vendor) => {
  return execute('exLogin', vendor);
};

export const exLogout = (vendorNum) => {
  let vendor = '';
  switch (Number(vendorNum)) {
    case 1:
      vendor = 'kakao';
      break;
    case 2:
      vendor = 'google';
      break;
    case 3:
      vendor = 'apple';
      break;
    default:
      vendor = '';
      break;
  }
  return execute('exLogout', vendor);
};
