import { useEffect, useState } from 'react';
import { Box, HStack, Image, Spinner } from '@chakra-ui/react';
import utils from '../utils';

const ImageModal = () => {
  const [locationSearch, setLocationSearch] = useState('');
  const [url, setUrl] = useState('');
  useEffect(() => {
    setLocationSearch(window.location.search);
  }, []);

  useEffect(() => {
    if (utils.isNotEmpty(locationSearch)) {
      const urlParams = new URLSearchParams(locationSearch);
      const id = urlParams.get('albumHistoryId');
      const url = utils.handleAlbumImage(id);
      setUrl(url);
    }
  }, [locationSearch]);

  return (
    <Box h="100%" w="100%" p={0} bg="black">
      <HStack h="100vh" justifyContent="center" alignItems="center">
        <Image
          src={url}
          fallback={
            <Spinner
              w="100px"
              h="100px"
              thickness="6px"
              speed="0.65s"
              color="gray.200"
              size="xl"
            />
          }
        />
      </HStack>
    </Box>
  );
};

export default ImageModal;
