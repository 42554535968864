import { useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  HStack,
  Text,
} from '@chakra-ui/react';

import MobilePetInfoForm from '../common/MobilePetInfoForm';
import usePet from '../../../hook/usePet';
import useModal from '../../../hook/useModal';
import utils from '../../../utils';

const MobileRegisterDrawer = (props) => {
  const { openModal } = useModal();
  const {
    DOG_CATEGORY,
    CAT_CATEGORY,
    prevPet,
    handleSetPrevPet,
    postPet,
    postPetModifyPet,
    resetPrevPet,
    getListPet,
  } = usePet();

  const [selectItems, setSelectItems] = useState(DOG_CATEGORY);

  const { isModify = false } = props;
  const { isOpen, onClose } = props;

  const childRef = useRef(null);

  useEffect(() => {
    if (prevPet.type === 1) {
      setSelectItems(DOG_CATEGORY);
    } else if (prevPet.type === 2) {
      setSelectItems(CAT_CATEGORY);
    }
  }, [prevPet.type]);

  const handleCheckInputs = async () => {
    if (utils.isEmpty(prevPet.name)) {
      openModal({ text: '이름을 입력해주세요' });
      if (childRef.current) {
        childRef.current.focusChildInput1();
      }
      return false;
    }
    if (utils.isEmpty(prevPet.birth)) {
      openModal({ text: '생일 입력해주세요' });
      if (childRef.current) {
        childRef.current.focusChildInput2();
      }
      return false;
    }
    if (utils.isEmpty(prevPet.category)) {
      openModal({
        text: `${prevPet.type === 1 ? '견종' : '묘종'}을 선택해주세요`,
      });
      if (childRef.current) {
        childRef.current.focusChildInput3();
      }
      return false;
    }
    if (utils.isEmpty(prevPet.height)) {
      openModal({ text: '키를 입력해주세요' });
      if (childRef.current) {
        childRef.current.focusChildInput4();
      }
      return false;
    }
    if (utils.isEmpty(prevPet.weight)) {
      openModal({ text: '몸무게를 입력해주세요' });
      if (childRef.current) {
        childRef.current.focusChildInput5();
      }
      return false;
    }
    return true;
  };

  const handleModify = async () => {
    const result = handleCheckInputs();
    if (result) {
      const tempPrevPet = { ...prevPet };
      tempPrevPet.birth = tempPrevPet.birth.replace(/-/g, '');
      const resultApi = await postPetModifyPet(tempPrevPet);
      if (resultApi) {
        resetPrevPet();
        openModal({
          text: '반려 동물 정보가 수정 되었습니다',
          onAgree: () => {
            getListPet();
            handleFinaly();
          },
        });
      }
    }
  };

  const handleRegister = async () => {
    const result = handleCheckInputs();
    if (result) {
      const resultApi = await postPet([prevPet]);
      if (resultApi) {
        resetPrevPet();
        openModal({
          text: '신규 반려 동물이 등록 되었습니다',
          onAgree: () => {
            getListPet();
            handleFinaly();
          },
        });
      }
    }
  };

  const handleClose = () => {
    handleFinaly();
  };

  const handleFinaly = () => {
    onClose();
  };

  return (
    <Drawer
      placement="bottom"
      onClose={handleClose}
      isOpen={isOpen}
      closeOnOverlayClick={false}
    >
      <DrawerOverlay />
      <DrawerContent borderTopRadius="20px">
        <DrawerBody px={0} py={0} pt="20px">
          <MobilePetInfoForm
            ref={childRef}
            pet={prevPet}
            retPet={(retPet) => {
              handleSetPrevPet(retPet);
            }}
            selectItems={selectItems}
          />
        </DrawerBody>
        <DrawerFooter px={0} pb={0}>
          <HStack spacing={0} w="100%">
            <Box w="50%">
              <Button
                onClick={handleClose}
                bg="#EEEEEE"
                h="60px"
                w="100%"
                borderRadius={0}
              >
                <Text color="#666" fontWeight={700} fontSize="16px">
                  취소
                </Text>
              </Button>
            </Box>
            <Box w="50%">
              <Button
                onClick={isModify ? handleModify : handleRegister}
                bg="#FBBC05"
                h="60px"
                w="100%"
                borderRadius={0}
              >
                <Text color="#000" fontWeight={700} fontSize="16px">
                  {isModify ? '수정하기' : '등록하기'}
                </Text>
              </Button>
            </Box>
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default MobileRegisterDrawer;
