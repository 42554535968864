import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { Flex } from '@chakra-ui/react';
import './styles.scss';

import ModalAgent from './components/alert/ModalAgent';
import { loadingState, makeImageloadingState } from './recoil/common';
import MobileRouters from './pages/MobileRouters';
import WebRouters from './pages/WebRouters';
import utils from './utils';

const App = () => {
  const setGlobalLoading = useSetRecoilState(loadingState);
  const setMakeImageloading = useSetRecoilState(makeImageloadingState);

  useEffect(() => {
    setGlobalLoading(false);
    setMakeImageloading(false);
  }, []);

  return (
    <Flex w="100%" className="App">
      <ModalAgent />
      {utils.isMobile() ? (
        <MobileRouters className="mobile" />
      ) : (
        <WebRouters className="web" />
      )}
    </Flex>
  );
};

export default App;
