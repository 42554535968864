import { useState, useEffect, useCallback } from 'react';
import {
  Avatar,
  Box,
  HStack,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import ContentBox from '../../../components/layouts/ContentBox';
import Navbar from '../../../components/layouts/Navbar';
import { CustomIcon } from '../../../components';
import { albumIdState } from '../../../recoil/userInfoRecoil';
import adminUserApi from '../../../apis/adminUserApi';
import { SUCCESS } from '../../../constant/errorCode';
import utils from '../../../utils';
import { API_URL } from '../../../constant/enviroment';

const AlbumMainDetail = () => {
  const navigate = useNavigate();
  const formHeight = 327;

  const resetAlbumId = useResetRecoilState(albumIdState);
  const albumId = useRecoilValue(albumIdState);
  const [albumUser, setAlbumUser] = useState({
    name: '',
    trackType: 0,
    petName: '',
    createdTime: '',
  });

  useEffect(() => {
    getNormalUser();
    return () => {
      resetAlbumId();
    };
  }, []);

  const getNormalUser = async () => {
    const param = {
      albumHistoryId: albumId,
    };
    const result = await adminUserApi.getAlbumHistory(param);
    if (result.errorCode === SUCCESS) {
      console.log(result.data);
      setAlbumUser(result.data);
    }
  };

  return (
    <ContentBox>
      <Navbar title="반려앨범 관리" />

      <Box className="form-box">
        <Box px="20px">
          <HStack className="form-header" justifyContent="start">
            <Box>
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                <CustomIcon name="left" color="#000000" />
              </IconButton>
            </Box>
          </HStack>
        </Box>
        <Box className="form-body" h={formHeight} px={8}>
          <VStack spacing={0}>
            <Box borderBottom="1px solid #ddd" w="100%">
              <HStack h="50px" alignContent="center">
                <Box w="100px">
                  <Text fontWeight={500} fontSize="16px" color="#757575">
                    반려인
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight={500} fontSize="16px">
                    {albumUser.name}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box borderBottom="1px solid #ddd" w="100%">
              <HStack h="50px" alignContent="center">
                <Box w="100px">
                  <Text fontWeight={500} fontSize="16px" color="#757575">
                    타입
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight={500} fontSize="16px">
                    {albumUser.trackType}번 타입
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box borderBottom="1px solid #ddd" w="100%">
              <HStack h="50px" alignContent="center" my={3}>
                <Box w="100px">
                  <Text fontWeight={500} fontSize="16px" color="#757575">
                    반려동물
                  </Text>
                </Box>
                <Box>
                  <HStack>
                    <Box>
                      <Text fontWeight={500} fontSize="16px">
                        {albumUser.petName}
                      </Text>
                    </Box>
                    <Box>
                      <Avatar
                        border="1px solid"
                        borderColor="#FBBC05"
                        boxSizing="border-box"
                        bg="#FBBC05"
                        src={`${utils.handleAlbumImage(albumId, 1)}`}
                      />
                    </Box>
                  </HStack>
                </Box>
              </HStack>
            </Box>
            <Box borderBottom="1px solid #ddd" w="100%">
              <HStack h="50px" alignContent="center" my={3}>
                <Box w="100px">
                  <Text fontWeight={500} fontSize="16px" color="#757575">
                    결과 이미지
                  </Text>
                </Box>
                <Box>
                  <Avatar
                    border="1px solid"
                    borderColor="#FBBC05"
                    boxSizing="border-box"
                    bg="#FBBC05"
                    src={`${utils.handleAlbumImage(albumId, 3)}`}
                  />
                </Box>
              </HStack>
            </Box>
            <Box w="100%">
              <HStack h="50px" alignContent="center">
                <Box w="100px">
                  <Text fontWeight={500} fontSize="16px" color="#757575">
                    시행일시
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight={500} fontSize="16px">
                    {utils.parseDateToStr(
                      albumUser.createdTime,
                      '-',
                      true,
                      ':',
                    )}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </VStack>
        </Box>
      </Box>
    </ContentBox>
  );
};

export default AlbumMainDetail;
