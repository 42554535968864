import { Tooltip } from '@chakra-ui/react';
import { isEmpty } from './common';
import { DELIVERY_PAYMENT_METHOD_LIST } from '../constant/common';

/**
 * ## date 타입 > string 포멧
 * @param {date} date: 대상 Array
 * @param {String} split: 구분으로 표현 될 값 (, -)
 * @param {Boolean} isTime: 시간까지 나올지 여부
 * @param {String} timeSplit: 시간 구분으로 표현될 값 (:, .)
 */
export const parseDateToStr = (
  date,
  split = '-',
  isTime = false,
  timeSplit = ':',
  isShowSecond = false,
) => {
  let tempDate = date;
  if (tempDate === undefined) return '날짜입력';
  if (tempDate.length === 8) {
    tempDate = new Date(tempDate.replace(/^(\d{4})(\d\d)(\d\d)$/, '$1-$2-$3'));
  } else if (typeof tempDate === 'string') {
    tempDate = new Date(tempDate);
  }

  const year = tempDate.getFullYear();
  const month = leftPad(tempDate.getMonth() + 1);
  const day = leftPad(tempDate.getDate());

  const hour = leftPad(tempDate.getHours());
  const minute = leftPad(tempDate.getMinutes());
  const second = leftPad(tempDate.getSeconds());

  const firstArr = [year, month, day];
  const secondArr = [hour, minute];
  if (isShowSecond) {
    secondArr.push(second);
  }
  if (isTime) {
    return `${firstArr.join(split)} ${secondArr.join(timeSplit)}`;
  }
  return [year, month, day].join(split);
};
export const parseDateToArr = (date, isTime = false, isShowSecond = false) => {
  let tempDate = date;
  // if (isEmpty(tempDate)) return [[], []];
  if (typeof tempDate !== 'object') {
    if (tempDate.length === 8) {
      // tempDate = Number(tempDate);
      // tempDate = new Date(tempDate);
      tempDate = new Date(
        tempDate.replace(/^(\d{4})(\d\d)(\d\d)$/, '$1-$2-$3'),
      );
    } else if (typeof tempDate === 'string') {
      tempDate = new Date(tempDate);
    }
  }

  const year = tempDate.getFullYear();
  const month = leftPad(tempDate.getMonth() + 1);
  const day = leftPad(tempDate.getDate());

  const hour = leftPad(tempDate.getHours());
  const minute = leftPad(tempDate.getMinutes());
  const second = leftPad(tempDate.getSeconds());

  const firstArr = [year, month, day];
  const secondArr = [hour, minute];
  if (isShowSecond) {
    secondArr.push(second);
  }
  if (isTime) {
    return [firstArr, secondArr.concat(second)];
  }
  return firstArr;
};
const leftPad = (value) => {
  if (value >= 10) {
    return value;
  }
  return `0${value}`;
};

export const parseTextLine = (value) => {
  let tempValue = value;
  if (isEmpty(tempValue)) return tempValue;
  if (tempValue.indexOf('<br />') > -1) {
    tempValue = tempValue.replaceAll('<br />', '\n');
  }
  if (tempValue.indexOf('<br>') > -1) {
    tempValue = tempValue.replaceAll('<br>', '\n');
  }
  return tempValue.split('\n');
};

export const parseLongText = (value, length) => {
  if (isEmpty(value)) {
    return value;
  }
  let tempValue = value;
  let tempLength = length;
  if (isEmpty(tempLength)) {
    tempLength = 10;
  }
  if (tempValue.length > tempLength) {
    tempValue = `${tempValue.substr(0, tempLength)}...`;
    return <Tooltip label={value}>{tempValue}</Tooltip>;
  }
  return tempValue;
};

export const parsePhoneNum = (value) => {
  if (isEmpty(value)) return;
  const cleaned = `${value}`.replace(/\D/g, '');
  if (value.length === 10) {
    const match = cleaned.match(/^(\d{2})(\d{3,4})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return value;
  }
  if (value.length === 11) {
    const match = cleaned.match(/^(\d{3})(\d{3,4})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return value;
  }
  return value;
};

/**
 * ## 일반적인 체크 get
 * @param {String} checkValue: 1: true, 2: false
 * @param {Boolean} isReverse: 반환 값 반대로
 * @returns
 */
export const parseGetFlag = (checkValue, isReverse) => {
  if (isReverse) {
    if (Number(checkValue) === 1) return false;
    return true;
  }
  return basicGetFlag(checkValue);
};
const basicGetFlag = (checkValue) => {
  if (Number(checkValue) === 1) return true;
  return false;
};

/**
 * ## 일반적인 체크 set
 * @param {String} checkValue: 1: true, 2: false
 * @param {Function} targetSetFunc: 셋팅 할 callback
 * @returns
 */
export const parseSetFlag = (checkValue, targetSetFunc) => {
  if (targetSetFunc) {
    if (Number(checkValue) === 2) return targetSetFunc(1);
    return targetSetFunc(2);
  }
  return basicSetFlag(checkValue);
};
const basicSetFlag = (checkValue) => {
  if (Number(checkValue) === 2) return 1;
  return 2;
};

export const parseAmount = (amount) => {
  if (isEmpty(amount)) {
    // console.log('## parseAmount amount', amount);
    return 0;
  }
  return Number(amount)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const parseTrackStyleValue = (trackStyle) => {
  // 1:chung_sun, 2:cute, 3:wooa, 4:myung_rang, 5:chic, 6:ek_sal
  switch (trackStyle) {
    case 1:
      return '청순한';
    case 2:
      return '귀여운';
    case 3:
      return '우아한';
    case 4:
      return '명랑한';
    case 5:
      return '시크한';
    case 6:
      return '익살스러운';
    default:
      return '';
  }
};

export const parseToFixedNum = (num, length = 2) => {
  let tempNum = num;
  if (Number.isNaN(tempNum)) return;
  if (typeof num === 'string') tempNum = Number(tempNum);
  tempNum = Number(tempNum.toFixed(length));
  return tempNum;
};
