import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { radioAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    width: '30px',
    height: '30px',
    border: '1px solid',
    borderColor: '#DDD',
    _hover: {
      border: '1px solid',
      borderColor: '#FBBC05 !important',
      color: '#FBBC05',
      background: '#FFF !important',
    },
    _checked: {
      borderColor: '#FBBC05 !important',
      padding: '0px',
      color: '#FBBC05',
      background: '#FFF !important',
    },
    _disabled: {
      borderColor: '#FBBC05 !important',
      padding: '1px',
      color: '#FBBC05',
      background: '#FFF !important',
    },
  },
});

export const radioTheme = defineMultiStyleConfig({ baseStyle });
