import { useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';

import Twitter from '../../../assets/images/twitter-circle-line.svg';
import FaceBook from '../../../assets/images/facebook-circle-line.svg';
import KakaoTalk from '../../../assets/images/kakao-talk-line.svg';
import useSNSShare from '../../../hook/useSNSShare';

const MobileShareDrawer = (props) => {
  const { isOpen, onClose } = props;
  const { id, text, url } = props;
  const { shareToTwitter, shareToFacebook, shareToKakaoTalk } = useSNSShare();

  const handleClose = () => {
    handleFinaly();
  };

  const handleFinaly = () => {
    onClose();
  };

  const handleShare = (type) => {
    if (type === 1) {
      shareToTwitter(text, url, id);
    }
    if (type === 2) {
      shareToFacebook(text, url, id);
    }
    if (type === 3) {
      shareToKakaoTalk(text, url, id);
    }
  };

  return (
    <Drawer
      placement="bottom"
      onClose={handleClose}
      isOpen={isOpen}
      closeOnOverlayClick={false}
    >
      <DrawerOverlay />
      <DrawerContent borderTopRadius="20px">
        <DrawerHeader py={2} borderBottom="1px solid #ddd">
          <Text textAlign="center" fontSize="18px">
            SNS 공유하기
          </Text>
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody p={0}>
          <VStack spacing={0}>
            <Box w="100%" borderBottom="1px solid #ddd">
              <Button
                onClick={() => {
                  handleShare(1);
                }}
                w="100%"
                h="50px"
                _hover={{}}
                _active={{}}
                color="#222222"
                border="none"
                size="md"
              >
                <HStack alignItems="center">
                  <Image src={Twitter} />
                  <Text className="custom-button-text">트위터</Text>
                </HStack>
              </Button>
            </Box>
            <Box w="100%" borderBottom="1px solid #ddd">
              <Button
                onClick={() => {
                  handleShare(2);
                }}
                w="100%"
                h="50px"
                _hover={{}}
                _active={{}}
                color="#222222"
                border="none"
                size="md"
              >
                <HStack alignItems="center">
                  <Image src={FaceBook} />
                  <Text className="custom-button-text">페이스북</Text>
                </HStack>
              </Button>
            </Box>
            <Box w="100%">
              <Button
                onClick={() => {
                  handleShare(3);
                }}
                w="100%"
                h="50px"
                _hover={{}}
                _active={{}}
                color="#222222"
                border="none"
                size="md"
              >
                <HStack alignItems="center" justifyContent="center">
                  <Image src={KakaoTalk} />
                  <Text className="custom-button-text">카카오톡</Text>
                </HStack>
              </Button>
            </Box>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default MobileShareDrawer;
