import { useRecoilValue } from 'recoil';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { ALBUM, MAIN, MEMBER } from '../constant/pageURL';
import { Loading } from '../components';
import { loadingState } from '../recoil/common';
import Login from './Login';
import MemberMain from './web/member/MemberMain';
import AlbumMain from './web/album/AlbumMain';
import AlbumMainDetail from './web/album/AlbumMainDetail';
import MemberMainDetail from './web/member/MemberMainDetail';
import AxiosForm from './AxiosForm';
import ImageModal from './ImageModal';

const WebRouters = () => {
  const globalLoadingState = useRecoilValue(loadingState);
  return (
    <BrowserRouter>
      <AxiosForm />
      {globalLoadingState && <Loading />}
      <Routes>
        <Route path="*" element={<Navigate to={MAIN.adminLogin} replace />} />
        <Route path={MAIN.adminLogin} element={<Login />} />
        <Route path={MEMBER.adminMember} element={<MemberMain />} />
        <Route path={MEMBER.adminMemberDetail} element={<MemberMainDetail />} />
        <Route path={ALBUM.adminAlbum} element={<AlbumMain />} />
        <Route path={ALBUM.adminAlbumDetail} element={<AlbumMainDetail />} />
        <Route path="/image" element={<ImageModal />} />
      </Routes>
    </BrowserRouter>
  );
};

export default WebRouters;
