import React from 'react';
import { Button, HStack, Text } from '@chakra-ui/react';

const ChipButton = (props) => {
  const {
    text = 'Button',
    size = 'md',
    variant = 'chip',
    isActive = false,
  } = props;
  const { onClick, disable = false } = props;

  return (
    <Button
      variant={variant}
      w="100%"
      onClick={onClick}
      isDisabled={disable}
      isActive={isActive || undefined}
      size={size}
    >
      <HStack alignItems="center">
        <Text className="custom-button-text" fontWeight={700}>
          {text}
        </Text>
      </HStack>
    </Button>
  );
};

export default ChipButton;
