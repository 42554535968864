import React from 'react';
import { Button, HStack, Text } from '@chakra-ui/react';

const CustomButton = (props) => {
  const {
    style,
    text = 'Button',
    theme,
    outline = false,
    size = 'md',
    leftIcon,
    rightIcon,
    id,
  } = props;
  const { onClick, active, disable = false } = props;

  let tempTheme = 'positive';
  if (theme !== undefined) {
    tempTheme = theme;
  }
  if (outline) {
    tempTheme += '.outline';
  }
  return (
    <Button
      className="custom-button"
      w="100%"
      onClick={onClick}
      id={id}
      isDisabled={disable}
      bg={`${tempTheme}.default`}
      _hover={{}}
      _active={{
        bg: `${tempTheme}.activeColor`,
      }}
      // leftIcon={leftIcon}
      // rightIcon={rightIcon}
      color={`${tempTheme}.fontColor`}
      borderColor={`${tempTheme}.borderColor`}
      size={size}
      style={{
        ...style,
        textDecoration: tempTheme === 'underline' ? 'underline' : '',
        borderColor: active ? '#02CD80' : '',
      }}
    >
      <HStack alignItems="center">
        {leftIcon || null}
        <Text className="custom-button-text" color={active ? '#02CD80' : ''}>
          {text}
        </Text>
        {rightIcon || null}
      </HStack>
    </Button>
  );
};

export default CustomButton;
