import { useEffect, useState, useCallback } from 'react';
import {
  Box,
  HStack,
  IconButton,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import ContentBox from '../../../components/layouts/ContentBox';
import Navbar from '../../../components/layouts/Navbar';
import { CustomIcon, DefaultPaginate } from '../../../components';
import { DEFAULT_CONTENT_NUM } from '../../../constant/common';
import adminUserApi from '../../../apis/adminUserApi';
import {
  albumIdState,
  normalUserIdState,
} from '../../../recoil/userInfoRecoil';
import { NO_DATA_ERROR, SUCCESS } from '../../../constant/errorCode';
import utils from '../../../utils';
import MainDog from '../../../assets/images/main-dog.svg';
import { ALBUM } from '../../../constant/pageURL';
import { API_URL } from '../../../constant/enviroment';

const MemberMainDetail = () => {
  const navigate = useNavigate();
  const formHeight = 627;

  const resetAlbumId = useResetRecoilState(albumIdState);
  const setAlbumId = useSetRecoilState(albumIdState);
  const resetNormalUserId = useResetRecoilState(normalUserIdState);
  const normalUserId = useRecoilValue(normalUserIdState);
  const [tempUserInfo, setTempUserInfo] = useState({
    name: '',
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const contentNum = 8;

  const [datas, setDatas] = useState([]);

  const headers = [
    { title: '번호', minWidth: '90px', width: 'auto' },
    { title: '반려인명', minWidth: '70px', width: 'auto' },
    { title: '타입', minWidth: '80px', width: 'auto' },
    { title: '반려동물', minWidth: '70px', width: 'auto' },
    { title: '결과이미지', minWidth: '120px', width: 'auto' },
    { title: '시행일시', minWidth: '120px', width: 'auto' },
  ];

  useEffect(() => {
    getNormalUser();
    return () => {
      // resetAlbumId();
      // resetNormalUserId();
    };
  }, []);

  useEffect(() => {
    getListAlbumHistory();
  }, [currentPage]);

  const getNormalUser = async () => {
    const param = {
      normalUserId,
    };
    const result = await adminUserApi.getNormalUser(param);
    if (result.errorCode === SUCCESS) {
      // result.data 형태가 아니라 아래와 같이 처리함
      setTempUserInfo(result);
    }
  };

  const getListAlbumHistory = async () => {
    const param = {
      pageNum: currentPage,
      contentNum,
    };
    const result = await adminUserApi.getListAlbumHistory(param);
    if (result.errorCode === SUCCESS) {
      setDatas(result.datas);
      setTotalCount(result.totalCount);
    } else if (result.errorCode === NO_DATA_ERROR) {
      setDatas([]);
      setTotalCount(1);
    }
  };

  const handleOnClickRow = (data) => {
    // setNormlaUserId(data.albumId);
    setAlbumId(data.albumHistoryId);
    navigate(ALBUM.adminAlbumDetail);
  };

  const handleOnErrorImage = (id) => {
    /*
    errorIds.push(id);
    setErrorIds(errorIds);
    */
  };

  const makeHeader = () => {
    return headers.map((element, index) => {
      const key = `${index}_key`;
      return (
        <Th
          key={key}
          minW={datas.length === 0 ? 'auto' : element.minWidth}
          w={element.width}
        >
          <Text>{element.title}</Text>
        </Th>
      );
    });
  };

  return (
    <ContentBox>
      <Navbar title="회원정보" />

      <Box className="form-box">
        <Box px="20px">
          <HStack className="form-header" justifyContent="start">
            <Box>
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                <CustomIcon name="left" color="#000000" />
              </IconButton>
            </Box>
          </HStack>
        </Box>
        <Box className="form-body" h={formHeight} px={8}>
          <VStack spacing={0}>
            <Box borderBottom="1px solid #ddd" w="100%">
              <HStack h="50px" alignContent="center">
                <Box w="100px">
                  <Text fontWeight={500} fontSize="16px" color="#757575">
                    회원명
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight={500} fontSize="16px">
                    {tempUserInfo.name || '탈퇴 회원'}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box borderBottom="1px solid #ddd" w="100%">
              <HStack h="50px" alignContent="center">
                <Box w="100px">
                  <Text fontWeight={500} fontSize="16px" color="#757575">
                    가입SNS
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight={500} fontSize="16px">
                    {utils.handleParseSns(tempUserInfo.snsType)}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box borderBottom="1px solid #ddd" w="100%">
              <HStack h="50px" alignContent="center">
                <Box w="100px">
                  <Text fontWeight={500} fontSize="16px" color="#757575">
                    성별
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight={500} fontSize="16px">
                    {`${utils.handleParseSex(tempUserInfo.sex)}성`}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box borderBottom="1px solid #ddd" w="100%">
              <HStack h="50px" alignContent="center">
                <Box w="100px">
                  <Text fontWeight={500} fontSize="16px" color="#757575">
                    생일
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight={500} fontSize="16px">
                    {utils.parseDateToStr(tempUserInfo.birth, '-')}
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box w="100%">
              <HStack h="50px" alignContent="center">
                <Box>
                  <Text fontWeight={500} fontSize="16px" color="#757575">
                    반려동물
                  </Text>
                </Box>
              </HStack>
            </Box>
          </VStack>
          <Box>
            <TableContainer
              style={{ marginTop: '0px' }}
              maxW="100%"
              h="100%"
              className="default-table"
            >
              <Table>
                <Thead>
                  <Tr>{makeHeader()}</Tr>
                </Thead>
                <Tbody>
                  {datas.length > 0 ? (
                    datas.map((data, bodyIndex) => {
                      const key = `td_${bodyIndex}`;

                      return (
                        <Tr
                          key={key}
                          _hover={{
                            cursor: 'pointer',
                            bg: 'rgba(245, 247, 251, 1)',
                          }}
                          onClick={() => {
                            handleOnClickRow(data);
                          }}
                        >
                          <Td>
                            <Text>
                              {utils.getPageContentNum(
                                bodyIndex,
                                currentPage,
                                totalCount,
                                contentNum,
                              )}
                            </Text>
                          </Td>
                          <Td>
                            <Text>{data.name}</Text>
                          </Td>
                          <Td>
                            <Text>{data.trackType}</Text>
                          </Td>
                          <Td>
                            <Text>{data.petName}</Text>
                          </Td>
                          <Td>
                            <HStack justifyContent="center" alignItems="center">
                              <Box>
                                <Image
                                  boxSize="40px"
                                  border="1px solid rgba(0, 0, 0, 0.10)"
                                  borderRadius="50%"
                                  src={utils.handleAlbumImage(
                                    data.albumHistoryId,
                                  )}
                                  onError={(e) => {
                                    // console.log(e.target.src, album.albumHistoryId);
                                    handleOnErrorImage(data.albumHistoryId);
                                  }}
                                  fallbackSrc={MainDog}
                                />
                              </Box>
                            </HStack>
                          </Td>
                          <Td>
                            <Text>
                              {utils.parseDateToStr(
                                data.createdTime,
                                '-',
                                true,
                                ':',
                              )}
                            </Text>
                          </Td>
                        </Tr>
                      );
                    })
                  ) : (
                    <Tr
                      style={{
                        height: '50px',
                      }}
                    >
                      <Td colSpan={headers.length}>
                        <Text
                          style={{
                            fontWeight: 700,
                            fontSize: '15px',
                          }}
                        >
                          등록된 반려동물 정보가 없습니다
                        </Text>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        <HStack className="form-footer" justifyContent="space-between">
          <Box>
            <DefaultPaginate
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              contentNum={contentNum}
              totalCount={totalCount}
            />
          </Box>
        </HStack>
      </Box>
    </ContentBox>
  );
};

export default MemberMainDetail;
