import { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
  tempAutoLoginState,
  tempSnsInfoState,
  userInfoState,
} from '../recoil/userInfoRecoil';
import normalUserApi from '../apis/normalUserApi';
import { MAIN } from '../constant/pageURL';
import { SUCCESS } from '../constant/errorCode';
import usePet from './usePet';
import { termInfoState } from '../recoil/common';
import utils from '../utils';

const useSign = () => {
  const { resetPetProcess } = usePet();
  const resetNormalUser = useResetRecoilState(userInfoState);
  const [normalUser, setNormalUser] = useRecoilState(userInfoState);

  const resetTempSnsInfo = useResetRecoilState(tempSnsInfoState);
  const [tempSnsInfo, setTempSnsInfo] = useRecoilState(tempSnsInfoState);

  const resetTempAutoLogin = useResetRecoilState(tempAutoLoginState);
  const [tempAutoLogin, setTempAutoLogin] = useRecoilState(tempAutoLoginState);

  const navigate = useNavigate();

  const resetTermInfo = useResetRecoilState(termInfoState);

  const loginNormalUser = useCallback(({ normalUserId, name, sex, birth }) => {
    setNormalUser({
      normalUserId,
      name,
      sex,
      birth,
      isLogin: true,
    });
  });

  const getNormalUserLogin = useCallback(async (data) => {
    const param = {
      snsToken: data.snsToken,
      snsType: data.snsType,
    };
    const result = await normalUserApi.getNormalUserLogin(param);
    if (result.errorCode === SUCCESS) {
      loginNormalUser({
        normalUserId: result.data.normalUserId,
        name: result.data.name,
        sex: result.data.sex,
        birth: result.data.birth,
      });
      utils.setAccessToken(result.data.accessToken);
      utils.setRefreshToken(result.data.refreshToken);
    }
    utils.setSnsInfo(data.snsToken, data.snsType);
    return result;
  });

  const postNormalUserSignUp = useCallback(async () => {
    const { snsToken, snsType } = tempSnsInfo;
    const param = {
      snsToken,
      snsType,
      name: normalUser.name,
      sex: normalUser.sex,
      birth: normalUser.birth.replace(/-/g, ''),
    };
    const result = await normalUserApi.postNormalUserSignUp(param);
    if (result.errorCode === SUCCESS) {
      utils.setAutoLogin(tempAutoLogin);
      const resultLogin = await getNormalUserLogin({
        snsToken,
        snsType,
      });
      if (resultLogin.errorCode === SUCCESS) {
        return true;
      }
    }
    return false;
  });

  const logoutNormalUser = useCallback(() => {
    const { snsType } = utils.getSnsInfo();
    utils.exLogout(snsType);
    handleLogoutNormalUser();
  });

  window.onResultKakaoLogout = (data) => {};

  window.onResultGoogleLogout = (data) => {};

  window.onResultAppleLogout = (data) => {};

  const handleLogoutNormalUser = async (data) => {
    utils.setAutoLogin(false);
    utils.setSnsInfo('', 0);
    utils.setAccessToken('');
    utils.setRefreshToken('');
    resetTempAutoLogin();
    resetNormalUser();
    resetTempSnsInfo();
    resetTermInfo();
    resetPetProcess();
    navigate(MAIN.mobileLogin);
  };

  return {
    tempAutoLogin,
    setTempAutoLogin,
    tempSnsInfo,
    setTempSnsInfo,
    normalUser,
    setNormalUser,
    loginNormalUser,
    logoutNormalUser,
    getNormalUserLogin,
    postNormalUserSignUp,
    resetNormalUser,
    handleLogoutNormalUser,
  };
};

export default useSign;
