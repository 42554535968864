import React, { useCallback, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import {
  albumHistoryFileState,
  albumHistoryPageState,
  albumHistoryState,
  listAlbumHistoryState,
} from '../recoil/albumHistoryRecoil';
import albumHistoryApi from '../apis/albumHistoryApi';
import { NO_DATA_ERROR, SUCCESS } from '../constant/errorCode';
import { trackTwoNameState } from '../recoil/common';

const useAlbumHistory = () => {
  const resetAlbumHistory = useResetRecoilState(albumHistoryState);
  const [albumHistory, setAlbumHistory] = useRecoilState(albumHistoryState);
  const resetAlbumHistoryFile = useResetRecoilState(albumHistoryFileState);
  const [albumHistoryFile, setAlbumHistoryFile] = useRecoilState(
    albumHistoryFileState,
  );
  const [listAlbumHistory, setListAlbumHistory] = useRecoilState(
    listAlbumHistoryState,
  );
  const [albumHistoryPage, setAlbumHistoryPage] = useRecoilState(
    albumHistoryPageState,
  );

  const [trackTwoName, setTrackTwoName] = useRecoilState(trackTwoNameState);

  const getListAlbumHistory = useCallback(async () => {
    const param = {
      pageNum: albumHistoryPage.pageNum,
      contentNum: albumHistoryPage.contentNum,
    };

    const result = await albumHistoryApi.getListAlbumHistory(param);
    if (result.errorCode === SUCCESS) {
      setListAlbumHistory(result.datas);
      setAlbumHistory(result.datas[0]);
      /*
      setAlbumHistoryPage({
        ...albumHistoryPage,
        totalPage: result.totalPage,
      });
      */
    } else if (result.errorCode === NO_DATA_ERROR) {
      setListAlbumHistory([]);
      resetAlbumHistory();
    }
  });

  const postAlbumHistory = useCallback(async (param, firstFile, secondFile) => {
    const result = await albumHistoryApi.postAlbumHistory(
      param,
      firstFile?.name ? firstFile : '',
      secondFile?.name ? secondFile : '',
    );
    if (result.errorCode === SUCCESS) {
      return result;
    }
  });

  return {
    albumHistoryPage,
    setAlbumHistoryPage,
    albumHistory,
    setAlbumHistory,
    albumHistoryFile,
    setAlbumHistoryFile,
    listAlbumHistory,
    setListAlbumHistory,
    getListAlbumHistory,
    postAlbumHistory,
    trackTwoName,
    setTrackTwoName,
    // reset
    resetAlbumHistory,
    resetAlbumHistoryFile,
  };
};

export default useAlbumHistory;
