import {
  useCallback,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Box,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';

const DescriptionInput = forwardRef((props, ref) => {
  const inputRef = useRef(null);

  useImperativeHandle(ref, () => {
    return {
      focus: () => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      },
    };
  });

  const { value, defaultValue = '', placeholder = '' } = props;
  const { type = 'text' } = props;
  const { selectItems = [] } = props;
  const { inputRight = '' } = props;
  const {
    isRequired = false,
    descText,
    descTextAlign = 'left',
    descFontWeight = 500,
    descFontSize = '15px',
    descFontColor = '#444',
  } = props;
  const { isDisabled = false, readOnly = false } = props;
  const { onChange, onKeyDown } = props;
  // chakra ui prop
  const { size = 'md', textAlign = 'left', bg = '#FFF' } = props;

  const handleAutoCompleteByType = useCallback(() => {
    if (type === 'password') {
      return 'new-password';
    }
    return 'off';
  });

  const handleOnChange = (event) => {
    if (onChange) {
      return onChange(event);
    }
  };

  const handleOnKeyDown = (event) => {
    /*
    if (event.keyCode === 13) {
      if (onKeyDown) {
        return onKeyDown(event);
      }
    }
    */
    if (onKeyDown) {
      return onKeyDown(event);
    }
  };

  return (
    <VStack>
      {descText && (
        <Box w="100%">
          <HStack spacing={1}>
            <Text
              textAlign={descTextAlign}
              fontWeight={descFontWeight}
              fontSize={descFontSize}
              color={descFontColor}
            >
              {descText}
            </Text>
            {isRequired && (
              <Text fontWeight={500} fontSize="15px" color="#EA4335">
                *
              </Text>
            )}
          </HStack>
        </Box>
      )}
      <Box w="100%">
        {type === 'select' ? (
          <Select
            ref={inputRef}
            placeholder={placeholder}
            value={value || 0}
            onChange={handleOnChange}
          >
            {selectItems.map((item, index) => {
              const key = `select_${index}`;
              return (
                <option key={key} name={item} value={index + 1}>
                  {item}
                </option>
              );
            })}
          </Select>
        ) : (
          <InputGroup>
            <Input
              ref={inputRef}
              name="default-input"
              autoComplete={handleAutoCompleteByType(type)}
              placeholder={placeholder}
              readOnly={readOnly}
              isDisabled={isDisabled}
              type={type}
              value={value || ''}
              onChange={handleOnChange}
              onKeyDown={handleOnKeyDown}
              bg={bg}
              size={size}
              textAlign={textAlign}
            />
            {inputRight && (
              <InputRightElement
                children={
                  <Text fontWeight={400} fontSize="15px" color="#555">
                    {inputRight}
                  </Text>
                }
              />
            )}
          </InputGroup>
        )}
      </Box>
    </VStack>
  );
});

export default DescriptionInput;
