import {
  Avatar,
  Box,
  Button,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import MobileContentBox from '../../components/layouts/MobileContentBox';
import { CustomIcon } from '../../components';

import { resultIdState, trackStyleState } from '../../recoil/common';
import useAlbumHistory from '../../hook/useAlbumHistory';
import usePet from '../../hook/usePet';
import RefreshIcon from '../../assets/images/refresh.svg';
import { MAIN } from '../../constant/pageURL';
import utils from '../../utils';
import MobileShareDrawer from '../../components/custom/common/MobileShareDrawer';

const MobileResult = () => {
  const { pet } = usePet();
  const {
    resetAlbumHistory,
    resetAlbumHistoryFile,
    trackTwoName,
    setTrackTwoName,
  } = useAlbumHistory();

  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();

  const [modalImageUrl, setModalImageUrl] = useState('');
  const [resultId, setResultId] = useRecoilState(resultIdState);
  const naviagte = useNavigate();

  const [trackStyle, setTrackStyle] = useRecoilState(trackStyleState);

  const handleShareText = () => {
    let tempShareText = `${utils.parseTrackStyleValue(trackStyle)} ${pet.name}`;
    if (trackStyle === 0) {
      tempShareText = `${trackTwoName} 닮은 ${pet.name}`;
    }
    return tempShareText;
  };

  useEffect(() => {
    return () => {
      setTrackStyle(1);
      resetAlbumHistory();
      resetAlbumHistoryFile();
      setTrackTwoName('');
      setResultId('');
    };
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <MobileContentBox>
      <Box
        className="center-cropped"
        position="relative"
        w="100%"
        h="calc(100% - 230px)"
      >
        <Image
          onClick={() => {
            const url = utils.handleAlbumImage(resultId, 3);
            setModalImageUrl(url);
            onOpen();
          }}
          w="100%"
          h="100%"
          src={utils.handleAlbumImage(resultId, 3)}
          overflow="hidden"
          fallback={
            <HStack w="100%" h="100%" justifyContent="center">
              <Spinner
                w="40px"
                h="40px"
                thickness="6px"
                speed="0.65s"
                color="gray.500"
                size="xl"
              />
            </HStack>
          }
        />
        {trackStyle !== 0 && (
          <Box w="100%" zIndex={15} position="absolute" bottom="40px">
            <HStack justifyContent="center">
              <Box>
                <Avatar
                  onClick={() => {
                    const url = utils.handleAlbumImage(resultId, 1);
                    setModalImageUrl(url);
                    onOpen();
                  }}
                  border="1px solid"
                  borderColor="#FBBC05"
                  boxSizing="border-box"
                  w="80px"
                  h="80px"
                  bg="#FBBC05"
                  src={utils.handleAlbumImage(resultId, 1)}
                />
              </Box>
            </HStack>
          </Box>
        )}
        {trackStyle === 0 && (
          <Box w="100%" zIndex={15} position="absolute" bottom="40px" border>
            <HStack justifyContent="center">
              <HStack bg="rgba(0, 0, 0, 0.7)" p="10px" borderRadius="300px">
                <Box>
                  <Avatar
                    onClick={() => {
                      const url = utils.handleAlbumImage(resultId, 1);
                      setModalImageUrl(url);
                      onOpen();
                    }}
                    border="1px solid"
                    borderColor="#FBBC05"
                    boxSizing="border-box"
                    w="80px"
                    h="80px"
                    bg="#FBBC05"
                    src={utils.handleAlbumImage(resultId, 1)}
                  />
                </Box>
                <Box>
                  <CustomIcon w="30px" h="30px" name="plus" color="#FFF" />
                </Box>
                <Box>
                  <Avatar
                    onClick={() => {
                      const url = utils.handleAlbumImage(resultId, 2);
                      setModalImageUrl(url);
                      onOpen();
                    }}
                    border="1px solid"
                    borderColor="#FBBC05"
                    boxSizing="border-box"
                    w="80px"
                    h="80px"
                    bg="#FBBC05"
                    src={utils.handleAlbumImage(resultId, 2)}
                  />
                </Box>
              </HStack>
            </HStack>
          </Box>
        )}
      </Box>
      <Box position="fixed" bottom="125px" w="100%" h="165px" bg="#FFF" py={6}>
        <VStack>
          <Box py={1}>
            {trackStyle !== 0 && (
              <HStack spacing={1}>
                <Text fontWeight={700} fontSize="16px" color="#222">
                  MungTok AI가 변환한
                </Text>
                <Text fontWeight={700} fontSize="16px" color="#EA4335">
                  {utils.parseTrackStyleValue(trackStyle)}
                </Text>
                <Text fontWeight={700} fontSize="16px" color="#4285F4">
                  {pet.name}
                </Text>
                <Text fontWeight={700} fontSize="16px" color="#222">
                  입니다
                </Text>
              </HStack>
            )}
            {trackStyle === 0 && (
              <VStack>
                <Box>
                  <Text fontWeight={700} fontSize="16px" color="#222">
                    MungTok AI가 변환한
                  </Text>
                </Box>
                <Box>
                  <HStack spacing={1}>
                    <Text fontWeight={700} fontSize="16px" color="#EA4335">
                      {trackTwoName}
                    </Text>
                    <Text fontWeight={700} fontSize="16px" color="#222">
                      닮은
                    </Text>
                    <Text fontWeight={700} fontSize="16px" color="#4285F4">
                      {pet.name}
                    </Text>
                    <Text fontWeight={700} fontSize="16px" color="#222">
                      입니다
                    </Text>
                  </HStack>
                </Box>
              </VStack>
            )}
          </Box>
          <Box pt={1}>
            <Avatar
              onClick={onOpenDrawer}
              bg="#FBBC05"
              icon={
                <Box w="25px" h="25px" pr="2px">
                  <CustomIcon name="fillShare" color="black" />
                </Box>
              }
            />
          </Box>
        </VStack>
      </Box>
      <Box position="fixed" bottom="60px" bg="#FFF1F0" w="100%" h="65px">
        <HStack h="65px" justifyContent="center" alignItems="center">
          <Box w="100%">
            <VStack>
              <Box>
                <Text fontWeight={400} fontSize="13px" color="#EA4335">
                  * 결과물은 반려앨범에서 다시 확인하실 수 있습니다
                </Text>
              </Box>
              <Box>
                <Text fontWeight={400} fontSize="13px" color="#EA4335">
                  * 사진이 다르면 결과물도 다를 수 있습니다
                </Text>
              </Box>
            </VStack>
          </Box>
        </HStack>
      </Box>
      <Box position="fixed" bottom={0} w="100%">
        <HStack spacing={0}>
          <Box w="50%">
            <Button
              onClick={() => {
                naviagte(MAIN.mobileRoot, { replace: true });
              }}
              bg="#DDDDDD"
              h="60px"
              w="100%"
              borderRadius={0}
              _hover={{}}
              _active={{}}
              leftIcon={<Image src={RefreshIcon} />}
            >
              <Text fontWeight={700} fontSize="16px" color="#666666">
                다시하기
              </Text>
            </Button>
          </Box>
          <Box w="50%">
            <Button
              onClick={() => {
                naviagte(MAIN.mobileRoot, { replace: true });
              }}
              bg="#FBBC05"
              h="60px"
              w="100%"
              borderRadius={0}
              _hover={{}}
              _active={{}}
              leftIcon={<CustomIcon name="thumbsUp" color="black" />}
            >
              <Text fontWeight={700} fontSize="16px">
                만족했어요
              </Text>
            </Button>
          </Box>
        </HStack>
      </Box>

      {isOpen && (
        <Modal onClose={onClose} size="full" isOpen={isOpen}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton onClick={onClose} color="#FFF" />
            <ModalBody h="100%" p={0} bg="black">
              <HStack h="100vh" justifyContent="center" alignItems="center">
                <Image
                  src={modalImageUrl}
                  fallback={
                    <Spinner
                      w="100px"
                      h="100px"
                      thickness="6px"
                      speed="0.65s"
                      color="gray.200"
                      size="xl"
                    />
                  }
                />
              </HStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {isOpenDrawer && (
        <MobileShareDrawer
          isOpen={isOpenDrawer}
          url={utils.handleAlbumImage(resultId, 3)}
          text={handleShareText()}
          id={resultId}
          onClose={() => {
            onCloseDrawer();
          }}
        />
      )}
    </MobileContentBox>
  );
};

export default MobileResult;
