import { atom } from 'recoil';
import { persistAtom } from './recoil-config';

export const loadingState = atom({
  key: 'loadingState',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const makeImageloadingState = atom({
  key: 'makeImageloadingState',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const fileIndexState = atom({
  key: 'fileIndexState',
  default: 0,
});

export const photoNameState = atom({
  key: 'photoNameState',
  default: '',
});

export const fileMethodState = atom({
  key: 'fileMethodState',
  default: 0, // 1 촬영, 2 앨범
});

export const trackTypeState = atom({
  key: 'trackTypeState',
  default: 1, // 1: 단순 변환, 2: 2사진 교환
});
export const trackStyleState = atom({
  key: 'trackStyleState',
  default: 0, // 0:track2 case, 1:chung_sun, 2:cute, 3:wooa, 4:myung_rang, 5:chic, 6:ek_sal
});

export const trackTwoNameState = atom({
  key: 'trackTwoNameState',
  default: '',
});

export const resultIdState = atom({
  key: 'resultIdState',
  default: '',
});

export const resultUrlState = atom({
  key: 'resultUrlState',
  default: '',
});

export const termInfoState = atom({
  key: 'termInfoState',
  default: {
    isTerm: false,
    term: false,
    personalInfo: false,
  },
});
//
