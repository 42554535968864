import {
  setSnsInfo,
  getSnsInfo,
  setAutoLogin,
  getAutoLogin,
  checkEmail,
  checkPhoneNum,
  changeObjectArray,
  isEmpty,
  setAccessToken,
  getAccessToken,
  setRefreshToken,
  getRefreshToken,
  isNotEmpty,
  getPageContentNum,
  removeNullValues,
  diffObjects,
  checkedProcess,
  downloadUrlFile,
  handleOnClickAllCheck,
  checkPassword,
  copyToClipboard,
  isMobile,
  OSInfo,
} from './common';
import {
  parseDateToStr,
  parseDateToArr,
  parseGetFlag,
  parseSetFlag,
  parseTextLine,
  parseLongText,
  parsePhoneNum,
  parseAmount,
  parseTrackStyleValue,
  parseToFixedNum,
} from './parseUtil';

import { execute, exLogin, exLogout } from './executeUtil';

import { handleParseSns, handleParseSex, handleAlbumImage } from './customUtil';

const utils = {
  parseToFixedNum,
  setSnsInfo,
  getSnsInfo,
  setAutoLogin,
  getAutoLogin,
  isMobile,
  setAccessToken,
  getAccessToken,
  setRefreshToken,
  getRefreshToken,
  copyToClipboard,
  checkPassword,
  downloadUrlFile,
  diffObjects,
  checkPhoneNum,
  parseAmount,
  removeNullValues,
  checkEmail,
  parseTextLine,
  checkedProcess,
  changeObjectArray,
  isEmpty,
  isNotEmpty,
  parseDateToStr,
  parseDateToArr,
  parseGetFlag,
  parseSetFlag,
  parseLongText,
  parsePhoneNum,
  parseTrackStyleValue,
  getPageContentNum,
  handleOnClickAllCheck,
  // common
  OSInfo,
  // exe
  execute,
  exLogin,
  exLogout,
  // custom
  handleParseSns,
  handleParseSex,
  handleAlbumImage,
};

export default utils;
