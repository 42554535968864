import { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { listPetState, petState, prevPetState } from '../recoil/petRecoil';
import petApi from '../apis/petApi';
import { NO_DATA_ERROR, SUCCESS } from '../constant/errorCode';
import utils from '../utils';

const usePet = () => {
  const [listPet, setListPet] = useRecoilState(listPetState);
  const resetPet = useResetRecoilState(petState);
  const [pet, setPet] = useRecoilState(petState);
  const resetPrevPet = useResetRecoilState(prevPetState);
  const [prevPet, setPrevPet] = useRecoilState(prevPetState);

  const DOG_CATEGORY = [
    '푸들',
    '비숑',
    '골든리트리버',
    '치와와',
    '시츄',
    '보더콜리',
  ];
  const CAT_CATEGORY = ['흰고양이', '검은고양이'];

  const isPrevData = useCallback(() => {
    if (utils.isNotEmpty(prevPet.name)) return true;
    if (utils.isNotEmpty(prevPet.category)) return true;
    if (utils.isNotEmpty(prevPet.birth)) return true;
    if (utils.isNotEmpty(prevPet.weight)) return true;
    if (utils.isNotEmpty(prevPet.height)) return true;
    return false;
  });

  const handleSetPet = useCallback((tempPet) => {
    /*
    {
      "petId": 0,
      "type": 1,
      "sex": 1,
      "name": "14124",
      "category": "",
      "birth": "2023-08-25",
      "weight": "",
      "height": ""
    }
    */
    setPet(tempPet);
  });

  const handleSetPrevPet = useCallback((tempPet) => {
    const temp = { ...tempPet };
    /*
    if (temp.petId) {
      delete temp.petId;
    }
    */
    setPrevPet(temp);
  });

  const getListPet = useCallback(async () => {
    const result = await petApi.getListPet();
    if (result.errorCode === SUCCESS) {
      setListPet(result.datas);
      // setPet(result.datas[0]);
    } else if (result.errorCode === NO_DATA_ERROR) {
      setListPet([]);
      resetPet();
    }
  });

  const deletePet = useCallback(async (item) => {
    const param = {
      petId: item.petId,
    };
    const result = await petApi.deletePet(param);
    if (result.errorCode === SUCCESS) {
      return true;
    }
    return false;
  });

  const postPetModifyPet = useCallback(async (item) => {
    const param = item;
    const result = await petApi.postPetModifyPet(param);
    if (result.errorCode === SUCCESS) {
      return true;
    }
    return false;
  });

  const postPet = useCallback(async (items) => {
    const param = [];
    items.forEach((item) => {
      const tempItem = {
        type: item.type,
        sex: item.sex,
        name: item.name,
        category: item.category,
        birth: item.birth.replace(/-/g, ''),
        weight: utils.parseToFixedNum(item.weight),
        height: utils.parseToFixedNum(item.weight),
      };
      param.push(tempItem);
    });

    const result = await petApi.postPet(param);
    if (result.errorCode === SUCCESS) {
      return true;
      // openModal({ text: result.message });
      // getListPet();
    }
    return false;
  });

  const resetPetProcess = () => {
    resetPet();
    setListPet([]);
  };

  return {
    DOG_CATEGORY,
    CAT_CATEGORY,
    pet,
    handleSetPet,
    prevPet,
    handleSetPrevPet,
    isPrevData,
    listPet,
    getListPet,
    postPet,
    postPetModifyPet,
    deletePet,
    resetPet,
    resetPrevPet,
    resetPetProcess,
  };
};

export default usePet;
