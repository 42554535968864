import { Box, HStack, Text, VStack } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/modal';
import { Button, IconButton } from '@chakra-ui/react';
import CustomButton from '../buttons/CustomButton';
import CustomIcon from '../icons/CustomIcon';
import useModal from '../../hook/useModal';
import utils from '../../utils';

const ConfirmModal = () => {
  const { modalDataState, closeModal } = useModal();
  return (
    <Modal
      isOpen={modalDataState.isOpen}
      onClose={closeModal}
      size="xs"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent alignSelf="center" h="150px" borderRadius="16px">
        <ModalHeader p={0} px={2} h="30px" borderBottom="1px solid #ddd">
          <Box w="100%" h="30px">
            <HStack justifyContent="end" alignItems="center">
              <Box>
                {modalDataState.isClose === true &&
                  !modalDataState.onCancel && (
                    <IconButton
                      h="100%"
                      size="xs"
                      onClick={closeModal}
                      border="none"
                      _active={{
                        bg: '#FDF6E4',
                      }}
                      _hover={{
                        bg: '#FDF6E4',
                      }}
                    >
                      <CustomIcon w="100%" h="100%" name="close" color="#000" />
                    </IconButton>
                  )}
              </Box>
            </HStack>
          </Box>
        </ModalHeader>
        <ModalBody h="100%" py="20px">
          {utils.isNotEmpty(modalDataState.text) && (
            <VStack spacing={1}>
              {utils
                .parseTextLine(modalDataState.text)
                .map((splitText, index) => {
                  const boxKey = `boxKey_${index}`;
                  return (
                    <Box key={boxKey}>
                      <Text textAlign="center">{splitText}</Text>
                    </Box>
                  );
                })}
            </VStack>
          )}
        </ModalBody>
        <ModalFooter p={0}>
          <HStack w="100%" justifyContent="center" spacing={0}>
            <Box w="50%">
              <Button
                bg="#DDD"
                border="none"
                borderRadius="0px"
                borderBottomLeftRadius="16px"
                w="100%"
                h="40px"
                _hover={{}}
                _active={{
                  opacity: 0.7,
                }}
                onClick={modalDataState.onCancel}
              >
                <Text
                  color="rgba(102, 102, 102, 1)"
                  fontWeight={700}
                  fontSize="14px"
                >
                  {modalDataState.onCancelText}
                </Text>
              </Button>
            </Box>
            <Box w="50%">
              <Button
                bg="#FBBC05"
                border="none"
                borderRadius="0px"
                borderBottomRightRadius="16px"
                w="100%"
                h="40px"
                _hover={{}}
                _active={{
                  opacity: 0.7,
                }}
                onClick={modalDataState.onAgree}
              >
                <Text color="#000" fontWeight={700} fontSize="14px">
                  {modalDataState.onAgreeText}
                </Text>
              </Button>
            </Box>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
