import {
  AiOutlineQuestionCircle as question,
  // AiOutlineCheckCircle as checkCircle,
  AiOutlineUndo as undo,
  AiOutlineCloseCircle as closeCircle,
  AiOutlineCalendar as calendar,
  AiOutlineMinus as minus,
  AiOutlinePlus as plus,
  AiOutlineLeft as left,
  AiOutlineRight as right,
  AiOutlineMenu as more,
  AiOutlineUser as account,
  AiOutlineArrowLeft as arrowLeft,
  // AiOutlineCheckSquare as checkSquare,
  AiOutlineClose as close,
  AiFillApple as fillApple,
  AiOutlineApple as apple,
  AiOutlineCheckCircle as checkCircle,
  AiFillCheckCircle as fillCheckCircle,
} from 'react-icons/ai';
import {
  FaArrowAltCircleRight as fillRightArrowCircle,
  FaPlusCircle as fillPlusCircle,
  // FaRegCheckCircle as checkCircle,
  // FaCheckCircle as fillCheckCircle,
} from 'react-icons/fa';

import { FcGoogle as google } from 'react-icons/fc';

import { BsFillShareFill as fillShare } from 'react-icons/bs';

import {
  BiBriefcase as briefcase,
  BiCheckSquare as checkSquare,
} from 'react-icons/bi';
import { IoWarningSharp as fillWarning } from 'react-icons/io5';
import {
  FiPlusCircle as plusCircle,
  FiStar as star,
  FiThumbsUp as thumbsUp,
} from 'react-icons/fi';
import {
  RiBusLine as bus,
  RiFileExcel2Line as excel,
  RiDownloadLine as downloadLine,
  RiFileCopyLine as copy,
  RiEdit2Line as edit,
  RiPrinterLine as print,
  RiVipDiamondLine as diamond,
  RiKakaoTalkFill as fillKakaoTalk,
  RiKakaoTalkLine as lineKakaoTalk,
  RiLogoutCircleLine as logout,
} from 'react-icons/ri';

import { RxDividerVertical as verticalLine } from 'react-icons/rx';

export default {
  logout,
  thumbsUp,
  fillShare,
  fillApple,
  apple,
  fillKakaoTalk,
  lineKakaoTalk,
  briefcase,
  more,
  account,
  question,
  google,
  calendar,
  undo,
  diamond,
  star,
  bus,
  arrowLeft,
  left,
  right,
  edit,
  copy,
  excel,
  downloadLine,
  checkSquare,
  checkCircle,
  fillCheckCircle,
  fillRightArrowCircle,
  plusCircle,
  fillPlusCircle,
  closeCircle,
  fillWarning,
  print,
  minus,
  plus,
  close,
  verticalLine,
};
