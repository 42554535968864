import { Box, Button, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  MobileConvertTop,
  MobileFileForm,
  MobileTrackStyleChip,
  SectionSplitor,
} from '../../components';
import useModal from '../../hook/useModal';

import { resultIdState, trackStyleState } from '../../recoil/common';
import MobileContentBox from '../../components/layouts/MobileContentBox';
import utils from '../../utils';
import { CONVERSION } from '../../constant/pageURL';
import useFile from '../../hook/useFile';
import useAlbumHistory from '../../hook/useAlbumHistory';
import usePet from '../../hook/usePet';
import Sample1 from '../../assets/images/sample1.svg';
import Sample2 from '../../assets/images/sample2.svg';
import Sample3 from '../../assets/images/sample3.svg';
import Sample4 from '../../assets/images/sample4.svg';

const MobileConvert = () => {
  const trackStyle = useRecoilValue(trackStyleState);
  const setResultId = useSetRecoilState(resultIdState);
  const naviagte = useNavigate();
  const { openModal } = useModal();
  const {
    firstSrc,
    setFirstSrc,
    firstFile,
    setFirstFile,
    secondSrc,
    setSecondSrc,
    secondFile,
    setSecondFile,
  } = useFile();

  // 삭제 지향
  const { pet } = usePet();
  const { trackTwoName, setTrackTwoName, postAlbumHistory } = useAlbumHistory();

  const handleConvertPhoto = async () => {
    if (pet.petId === 0) {
      openModal({ text: '반려동물을 선택해주세요' });
      return;
    }
    if (utils.isEmpty(firstFile?.name)) {
      openModal({ text: '이미지를 선택해주세요' });
      return;
    }
    if (trackStyle === 0) {
      if (utils.isEmpty(firstFile?.name) || utils.isEmpty(secondFile?.name)) {
        openModal({ text: '이미지를 선택해주세요' });
        return;
      }
      if (utils.isEmpty(trackTwoName)) {
        openModal({ text: '이름을 입력해주세요' });
        return;
      }
    }

    const param = {
      petId: pet.petId,
      style: trackStyle,
    };
    if (utils.isNotEmpty(trackTwoName)) {
      param.trackTwoName = trackTwoName;
    }
    const result = await postAlbumHistory(param, firstFile, secondFile);
    setResultId(result.albumHistoryId);
    naviagte(CONVERSION.mobileConvResult);
  };

  return (
    <MobileContentBox pb="60px">
      <VStack>
        {/* 반려동물 선택, 반려동물 등록 */}
        <MobileConvertTop />

        <SectionSplitor />

        {/* 파일 선택 */}
        <MobileFileForm
          firstSrc={firstSrc}
          setFirstSrc={setFirstSrc}
          firstFile={firstFile}
          setFirstFile={setFirstFile}
          secondSrc={secondSrc}
          setSecondSrc={setSecondSrc}
          secondFile={secondFile}
          setSecondFile={setSecondFile}
          trackTwoName={trackTwoName}
          setTrackTwoName={setTrackTwoName}
        />

        {/* 변환 스타일 (tarck1) */}
        {trackStyle !== 0 && <MobileTrackStyleChip />}
      </VStack>

      <Box bg="#FFF1F0" w="100%" mt="20px">
        <VStack p="20px">
          <Box>
            <VStack>
              <Box w="100%">
                <Text
                  textAlign="left"
                  fontWeight={500}
                  fontSize="15px"
                  color="#222"
                >
                  사진가이드
                </Text>
              </Box>
              <Box w="100%">
                <Text
                  textAlign="left"
                  fontWeight={400}
                  fontSize="12px"
                  color="#EA4335"
                >
                  - 사진 업로드시 얼굴 위주로 배경과 구분되는 사진을 촬영/업로드
                  해주세요
                </Text>
              </Box>
            </VStack>
          </Box>
          <Box w="100%">
            <HStack justifyContent="space-around" alignSelf="center">
              <Box>
                <Image src={Sample1} />
              </Box>
              <Box>
                <Image src={Sample2} />
              </Box>
              <Box>
                <Image src={Sample3} />
              </Box>
              <Box>
                <Image src={Sample4} />
              </Box>
            </HStack>
          </Box>
        </VStack>
      </Box>

      <Box position="fixed" bottom={0} w="100%">
        <Button
          bg="#FBBC05"
          h="60px"
          w="100%"
          borderRadius={0}
          onClick={handleConvertPhoto}
          _hover={{}}
          _active={{}}
        >
          <Text fontWeight={700} fontSize="16px">
            사람이라면?
          </Text>
        </Button>
      </Box>
    </MobileContentBox>
  );
};

export default MobileConvert;
