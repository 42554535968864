import api from './api';

const BASE = '/adminUser';

const getAdminUserLogin = async (data) => {
  const result = await api.get(`${BASE}/login`, {
    token: true,
    body: data,
    isAdmin: true,
  });
  return result.data;
};

const getListNormalUser = async (data) => {
  const result = await api.get(`${BASE}/listNormalUser`, {
    token: true,
    body: data,
    isAdmin: true,
  });
  return result.data;
};

const getListAlbumHistory = async (data) => {
  const result = await api.get(`${BASE}/listAlbumHistory`, {
    token: true,
    body: data,
    isAdmin: true,
  });
  return result.data;
};

const getIssueAccessToken = async (data) => {
  const result = await api.get(`${BASE}/issueAccessToken`, {
    token: true,
    body: data,
    independence: true,
    isAdmin: true,
  });
  return result.data;
};

const getNormalUser = async (data) => {
  const result = await api.get(`${BASE}/getNormalUser`, {
    token: true,
    body: data,
    isAdmin: true,
  });
  return result.data;
};

const getAlbumHistory = async (data) => {
  const result = await api.get(`${BASE}/getAlbumHistory`, {
    token: true,
    body: data,
    isAdmin: true,
  });
  return result.data;
};

const postAdminUserLogout = async (data) => {
  const result = await api.post(`${BASE}/logout`, {
    token: true,
    body: data,
    isAdmin: true,
  });
  return result.data;
};

export default {
  getAdminUserLogin,
  getListNormalUser,
  getListAlbumHistory,
  getIssueAccessToken,
  getNormalUser,
  getAlbumHistory,
  postAdminUserLogout,
};
