import React, { useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';
import { modalState } from '../recoil/modal';
import { isEmpty } from '../utils/common';

const useModal = () => {
  const [modalDataState, setModalDataState] = useRecoilState(modalState);
  const openModal = useCallback(
    ({ ...props }) => {
      return setModalDataState({
        type: props.type || 'alert',
        isOpen: true,
        isClose: isEmpty(props.isClose)
          ? modalDataState.isClose
          : props.isClose,
        title: props.title || '안내',
        text: props.text || '내용',
        onAgree: isEmpty(props.onAgree)
          ? closeModal
          : () => {
              props.onAgree();
              closeModal();
            },
        onAgreeText: props.onAgreeText || '확인',
        onCancel: isEmpty(props.onCancel)
          ? closeModal
          : () => {
              props.onCancel();
              closeModal();
            },
        onCancelText: props.onCancelText || '취소',
      });
    },
    [setModalDataState],
  );

  const closeModal = useCallback(() => {
    return setModalDataState((prev) => {
      return { ...prev, isOpen: false };
    });
  }, [setModalDataState]);

  return {
    modalDataState,
    openModal,
    closeModal,
  };
};

export default useModal;
