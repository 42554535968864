import { Box, Input, Text, VStack } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import UseFileImage from '../../file/image/UseFileImage';
import { trackStyleState } from '../../../recoil/common';

const MobileFileForm = (props) => {
  const trackStyle = useRecoilValue(trackStyleState);

  const {
    firstSrc,
    setFirstSrc,
    setFirstFile,
    secondSrc,
    setSecondSrc,
    setSecondFile,
    trackTwoName,
    setTrackTwoName,
  } = props;

  return (
    <Box px="20px" w="100%">
      <VStack>
        <Box w="100%">
          <Text fontWeight={700} fontSize="18px" textAlign="center">
            내 반려동물의 사진을 보여주세요
          </Text>
        </Box>
        <Box w="100%">
          <UseFileImage
            src={firstSrc}
            setFile={(src, file) => {
              setFirstSrc(src);
              setFirstFile(file);
            }}
          />
        </Box>
        {trackStyle === 0 && (
          <>
            <Box w="100%">
              <Text fontWeight={700} fontSize="18px" textAlign="center">
                반려인의 사진을 보여주세요
              </Text>
            </Box>
            <Box w="100%">
              <UseFileImage
                src={secondSrc}
                setFile={(src, file) => {
                  setSecondSrc(src);
                  setSecondFile(file);
                }}
              />
            </Box>
            <Box w="100%" py={2}>
              <Text fontWeight={500} fontSize="15px" color="#444">
                사진 속 인물의 이름을 알려주세요
              </Text>
            </Box>
            <Box w="100%">
              <Input
                placeholder="이름을 입력하세요"
                value={trackTwoName}
                onChange={(e) => {
                  setTrackTwoName(e.target.value);
                }}
              />
            </Box>
          </>
        )}
      </VStack>
    </Box>
  );
};

export default MobileFileForm;
